<div class="container col-12 bg-images-market">
	<!-- <p>home works!</p> -->
	<div class="overlay-market d-flex align-items-center">
		<div class="container col-10">
			<div class="row">
				<div class="col-12">
					<h1 class="marketFont">Our Markets</h1>
				</div>
				<div class="col-12">
					<label class="labelMarketFont">
						Thrive for better quality of life for a healthier future
					</label>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="marketOur" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5 colView fontMontserrat">
	<div class="row">
		<div class="col-12 col-md-6 col-lg-6 col-xl-6 py-5">
			<div class="col-12 text-justify colView">
				<p>MANSAR SCIENTIFIC products are distributed through a network of International and National
					distributors in Territories of Pacific rim , South East Asia, Central Asia ,Middle East and Africa
					and our products are utilized in hospital segments for both Private ,Government and International
					health organizations</p>
			</div>
			<div class="col-12 text-justify colView">
				<p>MANSAR SCIENTIFIC has expanded its presence in the medical market by introducing a vast portfolio of
					high quality medical products that are competitively priced. All products are backed by the
					company’s 100 percent satisfaction guarantee and personalized customer service. The products are
					used in a variety of medical specialties including General surgery, Gynecology, plastic surgery,
					Urology, Orthopedics, Nephrology and Cardiology.</p>
			</div>
		</div>
		<div class="col-12 col-md-6 col-lg-6 col-xl-6">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<div class="container col-6 rounded text-center py-1">
						<div class="bg-white shadow w3-card-4">
							<img class="bg-white m-3 rounded" src="../../assets/images/iconNatIntBus.png"
								alt="Card image cap" style="height: 3rem; width: 3rem;">
						</div>
					</div>
					<div class="container text-center col-12 py-1 colView">
						<strong>National and <br> International Business</strong>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<div class="container col-6 rounded text-center py-1">
						<div class="bg-white shadow w3-card-4">
							<img class="bg-white m-3 rounded" src="../../assets/images/iconHealthOrg.png"
								alt="Card image cap" style="height: 3rem; width: 3rem;">
						</div>
					</div>
					<div class="container text-center col-12 py-1 colView">
						<strong>Health organizations</strong>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<div class="container col-6 rounded text-center py-1">
						<div class="bg-white shadow w3-card-4">
							<img class="bg-white m-3 rounded" src="../../assets/images/iconHospital.png"
								alt="Card image cap" style="height: 3rem; width: 3rem;">
						</div>
					</div>
					<div class="container text-center col-12 py-1 colView">
						<strong>Government Hospital</strong>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<div class="container col-6 rounded text-center py-1">
						<div class="bg-white shadow w3-card-4">
							<img class="bg-white m-3 rounded" src="../../assets/images/iconClinicPrivate.png"
								alt="Card image cap" style="height: 3rem; width: 3rem;">
						</div>
					</div>
					<div class="container text-center col-12 py-1 colView">
						<strong>Private Clinic and Nursing</strong>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<div class="container col-6 rounded text-center py-1">
						<div class="bg-white shadow w3-card-4">
							<img class="bg-white m-3 rounded" src="../../assets/images/iconPrivateHospital.png"
								alt="Card image cap" style="height: 3rem; width: 3rem;">
						</div>
					</div>
					<div class="container text-center col-12 py-1 colView">
						<strong>Private Hospital</strong>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<div class="container col-6 rounded text-center py-1">
						<div class="bg-white shadow w3-card-4">
							<img class="bg-white m-3 rounded" src="../../assets/images/iconVetHospital.png"
								alt="Card image cap" style="height: 3rem; width: 3rem;">
						</div>
					</div>
					<div class="container text-center col-12 py-1 colView">
						<strong>Veterinary Hospitals</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>