<div class="container col-12 bg-images-home">
	<!-- <p>home works!</p> -->
	<div class="overlay-home"></div>
	<div id="vision" class="container col-12 col-md-12 col-lg-10 col-xl-10 mt-4 h-100">
		<div class="row pt-5 d-flex align-items-center">
			<div class="col-12 my-3 pt-5">
				<div class="row">
					<div class="d-flex justify-content-center align-items-center pl-5">
						<img src="../assets/images/arrowRight.png" alt="" />
						<h1 class="visionFont px-2">Vision</h1>
					</div>
					<div class="col-12 pl-5 labelFont">
						Thrive for better quality of life for a healthier future
					</div>
				</div>
			</div>
			<div class="col-12 my-3">
				<div class="row">
					<div class="d-flex justify-content-center align-items-center pl-5">
						<img src="../assets/images/arrowRight.png" alt="" />
						<h1 class="visionFont px-2">Mission</h1>
					</div>
					<div class="col-12 pl-5 labelFont">
						Continuously provide premium, innovative and trustworthy <br />
						healthcare solution for a better tomorrow
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="cardCarousel" class="container col-12 col-md-12 col-lg-10 col-xl-10 zIndex" style="top: -160px;">
	<div class="card">
		<div class="card-body">
			<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
				<div class="carousel-inner">
					<div class="carousel-item active">
						<div class="container col-10">
							<div class="row">
								<div
									class="col-12 col-md-2 col-xl-2 d-flex justify-content-center align-items-center pr-0">
									<img src="../assets/images/iconTrust.png" alt="iconQuality"
										style="height: 5rem;" />
								</div>
								<div class="col-12 col-md-10 col-xl-10">
									<div class="row">
										<div class="col-12">
											<h4>Trust</h4>
										</div>
										<div class="col-12">
											<span>
												Building partnership ensuring highest levels of
												performance to lives.
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="container col-10">
							<div class="row">
								<div
									class="col-12 col-md-2 col-xl-2 d-flex justify-content-center align-items-center pr-0">
									<img src="../assets/images/iconQuality.png" alt="iconQuality"
										style="height: 5rem;" />
								</div>
								<div class="col-12 col-md-10 col-xl-10">
									<div class="row">
										<div class="col-12">
											<h4>Quality</h4>
										</div>
										<div class="col-12">
											<span>
												Superior quality products that are highly accurate and
												consistent in their performance.
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="container col-10">
							<div class="row">
								<div
									class="col-12 col-md-2 col-xl-2 d-flex justify-content-center align-items-center pr-0">
									<img src="../assets/images/iconNovelty.png" alt="iconQuality"
										style="height: 5rem;" />
								</div>
								<div class="col-12 col-md-10 col-xl-10">
									<div class="row">
										<div class="col-12">
											<h4>Unique</h4>
										</div>
										<div class="col-12">
											<span>
												Being the only one of its kind, unlike anything else.
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="container col-10">
							<div class="row">
								<div
									class="col-12 col-md-2 col-xl-2 d-flex justify-content-center align-items-center pr-0">
									<img src="../assets/images/iconWorthy.png" alt="iconQuality"
										style="height: 5rem;" />
								</div>
								<div class="col-12 col-md-10 col-xl-10">
									<div class="row">
										<div class="col-12">
											<h4>Value</h4>
										</div>
										<div class="col-12">
											<span>
												Bringing high quality, cost-effective medical devices
												with market understanding and regulations.
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
					<span class="carousel-control-prev-icon bg-dark" aria-hidden="true"
						style="border-radius: 10px; border: 3px solid #343a40;"></span>
					<span class="sr-only">Previous</span>
				</a>
				<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
					<span class="carousel-control-next-icon bg-dark" aria-hidden="true"
						style="border-radius: 10px; border: 3px solid #343a40;"></span>
					<span class="sr-only">Next</span>
				</a>
			</div>
		</div>
	</div>
</div>

<div id="productOur" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5 mb-5">
	<div class="row">
		<div class="col-12 col-md-6 col-xl-6 d-flex justify-content-center align-items-center">
			<img class="" src="../assets/images/homeProductDocImg.png" style="height: 24rem;" />
		</div>
		<div class="col-12 col-md-6 col-xl-6">
			<div>
				<h3 class="pb-3 itemColor">Our Products</h3>
			</div>

			<div class="container">
				<div class="row">
					<div class="col-12 py-2">
						<div class="row">
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<div>
									<img src="../assets/images/iconConsumables.png" alt="WoundClosure icon"
										style="height: 2.5rem;" />
								</div>
							</div>
							<div class="btn-group col-10 d-flex justify-content-center align-items-center px-0">
								<button type="button" class="btn border-bottom pt-0" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false" routerLink="/surgicalConsumables">
									<div class="float-left itemColor">
										<b>Surgical Consumables</b>
									</div>
									<div class="float-right">
										<i class="arrow down justify-content-center align-items-end"></i>
									</div>
								</button>
								<!-- <div class="dropdown-menu dropdown-menu-right">
									<button class="dropdown-item" type="button">
										Surgical Gloves
									</button>
									<button class="dropdown-item" type="button">
										Microporus Tapes
									</button>
									<button class="dropdown-item" type="button">
										OT Drapes
									</button>
									<button class="dropdown-item" type="button">
										Surgeon Gowns
									</button>
									<button class="dropdown-item" type="button">
										IV Dressing
									</button>
									<button class="dropdown-item" type="button">Bandages</button>
									<button class="dropdown-item" type="button">Gauze</button>
								</div> -->
							</div>
						</div>
					</div>

					<div class="col-12 py-2">
						<div class="row">
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<div>
									<img src="../assets/images/iconInfusion.png" alt="WoundClosure icon"
										style="height: 2.5rem;" />
								</div>
							</div>
							<div class="btn-group col-10 d-flex justify-content-center align-items-center px-0">
								<button type="button" class="btn border-bottom pt-0" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false" routerLink="/infusion">
									<div class="float-left itemColor">
										<b>Infusion</b>
									</div>
									<div class="float-right">
										<i class="arrow down justify-content-center align-items-end"></i>
									</div>
								</button>
								<!-- <div class="dropdown-menu dropdown-menu-right">
									<button class="dropdown-item" type="button">
										CV Catheter
									</button>
									<button class="dropdown-item" type="button">
										IV Cannula
									</button>
									<button class="dropdown-item" type="button">
										IV Infusion Set
									</button>
									<button class="dropdown-item" type="button">
										Blood Transfusion Set
									</button>
									<button class="dropdown-item" type="button">
										Spinal Needle
									</button>
									<button class="dropdown-item" type="button">
										Syringes
									</button>
								</div> -->
							</div>
						</div>
					</div>					

					<!-- <div class="col-12 py-2">
						<div class="row">
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<div>
									<img src="../assets/images/iconCardiology.png" alt="WoundClosure icon"
										style="height: 2.5rem;" />
								</div>
							</div>
							<div class="btn-group col-10 d-flex justify-content-center align-items-center px-0">
								<button type="button" class="btn border-bottom pt-0" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false">
									<div class="float-left itemColor">
										<b>Cardiology</b>
									</div>
									<div class="float-right">
										<i class="arrow down justify-content-center align-items-end"></i>
									</div>
								</button>
								<div class="dropdown-menu dropdown-menu-right">
									<button class="dropdown-item" type="button">
										Angiography Catheter
									</button>
									<button class="dropdown-item" type="button">
										Dilatation Catheter
									</button>
									<button class="dropdown-item" type="button">
										Bare Metal Stent
									</button>
									<button class="dropdown-item" type="button">
										Drug Eluting Stent
									</button>
								</div>
							</div>
						</div>
					</div> -->
		
					<div class="col-12 py-2">
						<div class="row">
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<div>
									<img src="../assets/images/iconUrology.png" alt="WoundClosure icon"
										style="height: 2.5rem;" />
								</div>
							</div>
							<div class="btn-group col-10 d-flex justify-content-center align-items-center px-0">
								<button type="button" class="btn border-bottom pt-0" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false" routerLink="/urology">
									<div class="float-left itemColor">
										<b>Urology</b>
									</div>
									<div class="float-right">
										<i class="arrow down justify-content-center align-items-end"></i>
									</div>
								</button>
								<!-- <div class="dropdown-menu dropdown-menu-right">
									<button class="dropdown-item" type="button">
										Foley Catheter
									</button>
									<button class="dropdown-item" type="button">
										Foley Catheter
									</button>
									<button class="dropdown-item" type="button">
										Drainage Bag
									</button>
									<button class="dropdown-item" type="button">
										Meter Bag
									</button>
									<button class="dropdown-item" type="button">
										Urethral Catheter
									</button>
									<button class="dropdown-item" type="button">
										Urethral Dilator
									</button>
								</div> -->
							</div>
						</div>
					</div>

					<div class="col-12 py-2">
						<div class="row">
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<div>
									<img src="../assets/images/IconHaemodial.png" alt="iconHaemodialysis"
										style="height: 2.5rem;" />
								</div>
							</div>
							<div class="btn-group col-10 d-flex justify-content-center align-items-center px-0">
								<button type="button" class="btn border-bottom pt-0" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false" routerLink="/haemodialysis">
									<div class="float-left itemColor">
										<b>Haemodialysis</b>
									</div>
									<div class="float-right">
										<i class="arrow down justify-content-center align-items-end"></i>
									</div>
								</button>
								<!-- <div class="dropdown-menu dropdown-menu-right">
									<button class="dropdown-item" type="button">
										Haemo Dialyser
									</button>
									<button class="dropdown-item" type="button">
										HD Catheters
									</button>
									<button class="dropdown-item" type="button">
										Blood Tubing Set
									</button>
									<button class="dropdown-item" type="button">
										AV Fistula Needle
									</button>
									<button class="dropdown-item" type="button">
										Priming Tube
									</button>
									<button class="dropdown-item" type="button">
										On/Off kit
									</button>
									<button class="dropdown-item" type="button">Dialysate</button>
								</div> -->
							</div>
						</div>
					</div>				

					<div class="col-12 py-2">
						<div class="row">
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<div>
									<img src="../assets/images/iconWoundClosure.png" alt="WoundClosure icon"
										style="height: 2.5rem;" />
								</div>
							</div>
							<div class="btn-group col-10 d-flex justify-content-center align-items-center px-0">
								<button type="button" class="btn border-bottom pt-0" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false" routerLink="/woundManagement">
									<div class="float-left itemColor">
										<b>Wound Management</b>
									</div>
									<div class="float-right">
										<i class="arrow down justify-content-center align-items-end"></i>
									</div>
								</button>
								<!-- <div class="dropdown-menu dropdown-menu-right">
									<button class="dropdown-item" type="button">
										Surgical Sutures
									</button>
									<button class="dropdown-item" type="button">
										Hernia Mesh
									</button>
									<button class="dropdown-item" type="button">
										Skin Staplers
									</button>
									<button class="dropdown-item" type="button">
										Tissue Adhesive
									</button>
									<button class="dropdown-item" type="button">Haemostat</button>
								</div> -->
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
	</div>
</div>