<div class="container col-12 bg-images-about">
	<!-- <p>home works!</p> -->
	<div class="overlay-about d-flex align-items-center">
		<div class="container col-10">
			<div class="row">
				<div class="col-12">
					<h1 class="aboutFont">About Us</h1>
				</div>
				<div class="col-12">
					<label class="labelAboutFont">
						Thrive for better quality of life for a healthier future
					</label>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="aboutUs" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5 colView">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<p class="fontMontserrat">
				MANSAR SCIENTIFIC is a leading Med-tech company specializing and
				marketing of range of medical devices for Wound closure ,Haemodialysis ,
				Urology, Infusions, and Surgical Consumables segments.
			</p>
		</div>
		<div class="listAbout py-5">
			<ul class="p-0 fontMontserrat">
				<ol class="py-2">
					<img class="pr-2" src="../../assets/images/arrowOrange.png" alt="" style="height: 1.5rem;" />
					A full spectrum of healthcare products: a solution for every need.
				</ol>
				<ol class="py-2">
					<img class="pr-2" src="../../assets/images/arrowOrange.png" alt="" style="height: 1.5rem;" />
					Quality benchmarks: on par with global leaders.
				</ol>
				<ol class="py-2">
					<img class="pr-2" src="../../assets/images/arrowOrange.png" alt="" style="height: 1.5rem;" />
					High-precision products, below 0.001% failure record.
				</ol>
				<ol class="py-2">
					<img class="pr-2" src="../../assets/images/arrowOrange.png" alt="" style="height: 1.5rem;" />
					Widespread network: always accessible with full support.
				</ol>
				<ol class="py-2">
					<img class="pr-2" src="../../assets/images/arrowOrange.png" alt="" style="height: 1.5rem;" />
					Highly professional, ethical operations.
				</ol>
			</ul>
		</div>
	</div>
</div>

<div id="quoteDouble" class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-5 colView">
	<div class="container col-12 col-md-12 col-lg-10 col-xl-10">
		<div class="row col-12 m-0 p-0 text-justify">
			<div class="col-12 col-md-12 col-lg-12 col-xl-12">
				<blockquote class="brooks">
					Our Legacy of product performance comes through our Customer support
					which is our core value to differentiate ourselves from our
					competitors by our ability to respond quickly and effectively to all
					of our customer inquiries. MANSAR SCIENTIFIC was found on the belief
					that our customers’ success is our greatest achievement.
				</blockquote>
			</div>
			<div class="col-12 col-md-12 col-lg-12 col-xl-12">
				<blockquote class="groucho">
					In a dynamic marketplace, We provide the highest degree of attention
					to our customers by providing valuable services, such as field support
					and marketing materials, to help our customers achieve success in an
					increasingly fierce marketplace. We ensure distributors and end-users
					have a high degree of knowledge and confidence in the products we
					provide.
				</blockquote>
			</div>
		</div>
	</div>
</div>