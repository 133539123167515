import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgxPageScrollModule } from 'ngx-page-scroll';

import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { ViewProductComponent } from './viewProduct/viewProduct.component';
import { QualityComponent } from './quality/quality.component';
import { WoundClosureComponent } from './woundClosure/woundClosure.component';
import { HaemodialysisComponent } from './haemodialysis/haemodialysis.component';
import { UrologyComponent } from './urology/urology.component';
import { InfusionComponent } from './infusion/infusion.component';
import { ConsumablesComponent } from './consumables/consumables.component';
import { OurMarketsComponent } from './ourMarkets/ourMarkets.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		AboutComponent,
		ProductsComponent,
		ViewProductComponent,
		QualityComponent,
		WoundClosureComponent,
		HaemodialysisComponent,
		UrologyComponent,
		InfusionComponent,
		ConsumablesComponent,
		OurMarketsComponent,
		ContactComponent
	],
	imports: [
		BrowserModule,
		NgxPageScrollModule,
		RouterModule.forRoot([
			{ path: 'home', component: HomeComponent },
			{ path: 'aboutus', component: AboutComponent },
			{ path: 'woundManagement', component: WoundClosureComponent },
			{ path: 'haemodialysis', component: HaemodialysisComponent },
			{ path: 'urology', component: UrologyComponent },
			{ path: 'infusion', component: InfusionComponent },
			{ path: 'surgicalConsumables', component: ConsumablesComponent },
			{ path: 'ourmarket', component: OurMarketsComponent },
			// { path: 'productOur', component: ProductsComponent },
			{ path: 'products/:type', component: ViewProductComponent },
			{ path: 'qualityManagement', component: QualityComponent },
			{ path: 'contactUs', component: ContactComponent },
			{ path: '**', component: HomeComponent, pathMatch: 'full' },
		]),
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule { }
