import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
	templateUrl: './viewProduct.component.html',
	styleUrls: ['./viewProduct.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ViewProductComponent implements OnInit {

	public product: string;
	public mySubProduct: any;
	public subProduct = {
		"KNOT-CRYIL": {
			name: 'KNOT-CRYIL',
			sub: [
				{
					'title': "POLYGLACTIN 910 SUTURES"
				},
				{
					'descrip': "Braided synthetic absorbable Polyglactin 910 violet or undyed sutures with drilled end curved needles"
				},
				{
					'proDescrip': "KNOT -CRYIL , Our Polyglactin 910 suture are known for their high quality and undoubted performance. Made-up of braided and coated 90% Polyglycolic acid and 10% lactic acid, these are superior in absorption with shelf life of 5 years. Available in convenient and safe packaging, these sutures are appreciated by our most customers."
				}
			],
			advantages: [
				{
					'advan': "High tensile strength"
				},
				{
					'advan': "Excellent knotting"
				},
				{
					'advan': "Smooth passage through tissue"
				},
				{
					'advan': "Silicon coated atraumatic needle."
				},
				{
					'advan': "Easy retrieval pack"
				}
			],
			// dataAdvantages: [
			// 	["High tensile strength"],
			// 	["Excellent knotting"],
			// 	["Smooth passage through tissue"],
			// 	["Silicon coated atraumatic needle."],
			// 	["Easy retrieval pack"]
			// ],
			absProfile: [
				{
					'absPro': "Wound Support: 27-30 Days"
				},
				{
					'absPro': "Mass Absorption: 56-70 Days"
				}
			],
			ranges: [
				{
					'range': "U.S.P Sizes available: 6-0 to 2"
				},
				{
					'range': "Suture length available with needle: 45cms to 180cms"
				},
				{
					'range': "Needle lengths: 12mm to 50mm across all needle point profiles"
				}
			],
			// Image: [
			// 	{'imageUrl': "assets/MSImages/woundClosure/KNOTcRIYIL.png"}
			// 		// 'image': "assets/MSImages/woundClosure/KNOTcRIYIL.png"

			// ]
			imageUrl: "assets/MSImages/woundClosure/KNOTcRIYIL.png"
		},
		"KNOT-CRYIL-FAST": {
			name: 'KNOT-CRYIL FAST',
			sub: [
				{
					'title': "POLYGLACTIN 910 FAST ABSORBING SUTURES"
				},
				{
					'descrip': "Braided synthetic  fast absorbable Polyglactin 910 undyed  sutures  with drilled end curved needles"
				},
				{
					'proDescrip': "KNOT -CRYIL FAST  , Our  Fast absorbable Polyglactin 910 acid sutures are made up of braided and coated Polyglactin 910 acid, meant for faster absorption. Knot-Cyril Fast is recommended only when faster absorption is desired. The higher homo-polymer of glycolide, is reduced to simpler polymer for fast absorption."
				}
			],
			advantages: [
				{
					'advan': "Absorbed by hydrolysis."
				},
				{
					'advan': "Gets absorbed within 42 days."
				},
				{
					'advan': "Excellent knot security."
				},
				{
					'advan': "Silicon coated autramatic needle."
				}
			],
			absProfile: [
				{
					'absPro': "Wound Support: 7-10 Days"
				},
				{
					'absPro': "Mass Absorption: 40-42 Days"
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 5-0 to 2"
				},
				{
					'range': "Suture length available with needle: 45cms to 140cms"
				},
				{
					'range': "Needle lengths: 16mm to 38 mm attached to various needle point profiles"
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOTcRYILfAST.png"
		},
		"KNOT-MONO": {
			name: 'KNOT-MONO',
			sub: [
				{
					'title': "POLYGLECAPRONE-25 SUTURE"
				},
				{
					'descrip': "Monofilament synthetic absorbable Polyglecaprone-25 violet or undyed sutures with drilled end curved needles"
				},
				{
					'proDescrip': "KNOT -MONO, Our Poliglecaprone 25 suture is monofilament synthetic absorbable surgical suture prepared from a copolymer of glycolide and caprolactone. It is slippery, making it easier to pass better  than a braided suture. Poliglecaprone 25 suture is absorbed by hydrolysis. Poliglecaprone is available as Violet and Undyed."
				}
			],
			advantages: [
				{
					'advan': "Minimal Scar, better cosmesis."
				},
				{
					'advan': "Predictable absorption."
				},
				{
					'advan': "Easy tissue glide."
				},
				{
					'advan': "Exceptional pliability, soft and supple."
				},
				{
					'advan': "High tensile strength, twice that of chromic catgut."
				},
				{
					'advan': "Silicon coated autramatic needle."
				}
			],
			absProfile: [
				{
					'absPro': "Wound Support: 3-4 weeks"
				},
				{
					'absPro': "Mass Absorption: 90-120 Days"
				}
			],
			ranges: [
				{
					'range': "U.S.P. Sizes: 5-0 to 2 Knot-Mono Undyed, 2-0 to 2 Knot -Mono Dyed"
				},
				{
					'range': "Suture length available with needle: 35cms to 150cms Alpha-Mono Undyed, 76cms to 152cms Knot-Mono Dyed"
				},
				{
					'range': "Suture length available without needle: 152cms (0 and 1 size)"
				},
				{
					'range': "Needle lengths: 13mm to 60 mm attached to various needle point profiles"
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOtMONO.png"
		},
		"KNOT-PDO": {
			name: 'KNOT-PDO',
			sub: [
				{
					'title': "POLYDIOXANON SUTURE"
				},
				{
					'descrip': "Monofilament  synthetic absorbable Polydioxanon violet or undyed sutures  with drilled end curved needles"
				},
				{
					'proDescrip': "KNOT-PDO , Our Polydioxanone suture is the strongest available synthetic absorbable sutures for extended wound support. Knot -PDO sutures are monofilament Polydioxanone sutures. Polydiaxanone suture is smooth, pliable with least memory. Its violet colour imparts enhanced visibility."
				}
			],
			advantages: [
				{
					'advan': "Polydiaxanone sutures are particularly useful where the combination of an absorbable suture and extended support is desirable."
				},
				{
					'advan': "Predictable absorption."
				},
				{
					'advan': "Smooth passage through tissue."
				},
				{
					'advan': "Loop suture available."
				},
				{
					'advan': "Silicon coated autramatic needle."
				}
			],
			absProfile: [
				{
					'absPro': "Wound Support: 5-6 Weeks"
				},
				{
					'absPro': "Mass Absorption: 180-210 Days"
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 5-0 to 1"
				},
				{
					'range': "Suture length available with needle: 70cms to 150cms"
				},
				{
					'range': "Needle lengths: 20mm to 50 mm attached to various needle point profiles"
				},
				{
					'range': "Also available as LOOP suture in sizes 0 and 1 with 150cms length and 50mm needle"
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOtPDO.png"
		},
		"KNOT-GUT-CHROMIC": {
			name: 'KNOT-GUT CHROMIC',
			sub: [
				{
					'title': "CROMIC CATGUT SUTURES"
				},
				{
					'descrip': "Multifilament natural  absorbable chromic catgut  sutures with drilled end curved needles"
				},
				{
					'proDescrip': "KNOTGUT- CHROMIC , Our superior quality “chromic catgut sutures” are offered  to different surgical procedures. These are generally used in soft tissue approximation and/or ligation. It is an absorbable sterile surgical suture composed of purified connective tissues derived from the sub mucosal fibrous layer of ovine intestines. Chromic catgut is absorbed by proteolyticenzymes in body. Chromic catgut is treated with chromic salt solution to resist body enzymes, thereby prolonging the absorption time over 90 days."
				}
			],
			advantages: [
				{
					'advan': "High purity collagen."
				},
				{
					'advan': "Accurate polishing ensures smooth tissue passage."
				},
				{
					'advan': "Packed in IPA to reduce memory & increase pliability."
				},
				{
					'advan': "Uniform chrome content provides required wound support and absorption."
				},
				{
					'advan': "Uniform twist spread along the suture length and controlled drying provide."
				}
			],
			absProfile: [
				{
					'absPro': "Wound Support: 14-21 Days."
				},
				{
					'absPro': "Mass Absorption: 14-21 Days."
				}
			],
			ranges: [
				{
					'range': "U.S.P Sizes available 5-0 to 2."
				},
				{
					'range': "Suture length available with needle: 76cms to 152cms Monoglyde Undyed"
				},
				{
					'range': "Suture length available without needle: 152cms (3-0 and 3 size)"
				},
				{
					'range': "Needle lengths: 12mm to 60 mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOTgUTcHROMIC.png"
		},
		"KNOT-GUT-PLAIN": {
			name: 'KNOT-GUT PLAIN',
			sub: [
				{
					'title': "PLAIN CATGUT SUTURES"
				},
				{
					'descrip': "Multifilament natural absorbable  Plain catgut  sutures with drilled end curved needles"
				},
				{
					'proDescrip': "KNOT -GUT PLAIN , Our superior quality “plain catgut sutures” are offered for different surgical procedures. These are generally used in soft tissue approximation and/or ligation. It is an absorbable sterile surgical suture composed of purified connective tissues derived from the sub mucosal fibrous layer of ovine intestines. Plain catgut is absorbed by proteolytic enzymes in body."
				}
			],
			advantages: [
				{
					'advan': "High purity collagen."
				},
				{
					'advan': "Accurate polishing ensures smooth tissue passage."
				},
				{
					'advan': "Packed in IPA to reduce memory & increase pliability."
				},
				{
					'advan': "Uniform twist spread along the suture length and controlled drying provide high tensile strength."
				}
			],
			absProfile: [
				{
					'absPro': "Wound Support: 7-10 Days."
				},
				{
					'absPro': "Mass Absorption: 60-70 Days."
				}
			],
			ranges: [
				{
					'range': "U.S.P. Sizes: 5-0 to 2."
				},
				{
					'range': "Suture length available with needle: 76cms to 152cms."
				},
				{
					'range': "Suture length available without needle: 152cms (3-0 and 3 size)."
				},
				{
					'range': "Needle lengths: 12mm to 60 mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOTgUTpLAIN.png"
		},
		"KNOT-SILK": {
			name: 'KNOT-SILK',
			sub: [
				{
					'title': "SILK SUTURES"
				},
				{
					'descrip': "Braided Non absorbable Silk black suture with drilled end curved needle"
				},
				{
					'proDescrip': "KNOT -SILK , Our Black braided silk sutures are in  most demanding for major  surgical procedures. Made of quality material, these offer ease of use and application and provided with several special features to give strength and handling properties. These can be supplied in customized packaging to meet specific requirements."
				}
			],
			advantages: [
				{
					'advan': "Silicon coated autramatic needle ."
				},
				{
					'advan': "Good knot security."
				},
				{
					'advan': "Excellent handling property."
				},
				{
					'advan': "Easy retrieval pack."
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 6-0 to 4"
				},
				{
					'range': "Suture length available with needle: 35cms to 90cms."
				},
				{
					'range': "Needle lengths: 12mm to 50mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOtSILK.png"
		},
		"KNOT-BOND": {
			name: 'KNOT-BOND',
			sub: [
				{
					'title': "POLYESTER SUTURES"
				},
				{
					'descrip': "Braided Non absorbable Polyester green and white suture with drilled end curved needle"
				},
				{
					'proDescrip': "KNOT-BOND , Our Polyester sutures braided and coated, polyester sutures are known for superior knot sliding performance and ease of manipulation."
				},
				{
					'proDescrip': "Made from quality material, these are available in different sizes and specifications to satisfy our most demanding customers."
				}
			],
			advantages: [
				{
					'advan': "Silicon coated autramatic needle."
				},
				{
					'advan': "Compactly braided."
				},
				{
					'advan': "Extra smooth."
				},
				{
					'advan': "Excellent knot tie-down and knot security."
				},
				{
					'advan': "Minimised tissue drag and sawing."
				},
				{
					'advan': "Excellent handling qualities."
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 6-0 to 5"
				},
				{
					'range': "Suture length available with needle: 45cms to 100cms."
				},
				{
					'range': "Needle lengths:12mm to 50mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOTbOND.png"
		},
		"KNOT-LON": {
			name: 'KNOT-LON',
			sub: [
				{
					'title': "NYLON SUTURES"
				},
				{
					'descrip': "Monofilament  Non absorbable Nylon  black or undyed  suture with drilled end curved needle"
				},
				{
					'proDescrip': "KNOT-LON , Our Polyamide sutures are in most demand for major surgical procedures. Knot-Lon (Polyamide/Nylon) suture is made from quality material attached to variety of stainless steel needles like round bodied, cutting, reverse cutting, taper cutting, taper point and many more. These are non-absorbable monofilament surgical sutures and do not require any coating."
				}
			],
			advantages: [
				{
					'advan': "Silicon coated autramatic needle."
				},
				{
					'advan': "High tensile strength."
				},
				{
					'advan': "Excellent elasticity."
				},
				{
					'advan': "Smooth and non-fraying."
				},
				{
					'advan': "Easy retrieval pack."
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 6-0 to 2"
				},
				{
					'range': "Suture length available with needle: 35cms to 150cms."
				},
				{
					'range': "Needle lengths: 12mm to 60mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOtLON.png"
		},
		"KNOT-LENE": {
			name: 'KNOT-LENE',
			sub: [
				{
					'title': "POLYPROPYLENE SUTURES"
				},
				{
					'descrip': "Monofilament  Non absorbable Polypropylene  blue suture with drilled end curved needle"
				},
				{
					'proDescrip': "KNOT-LENE , Our Polypropylene sutures are a non-absorbable synthetic monofilament suture made of polypropylene, composed of Polypropylene, a synthetic linear polyolefin. Knot-Lene is a monofilament uncoated suture, coloured blue by a colour additive. Polypropylene sutures advantages include minimal tissue reactivity and durability."
				}
			],
			advantages: [
				{
					'advan': "Silicon coated autramatic needle."
				},
				{
					'advan': "Easy visibility."
				},
				{
					'advan': "Non-adhering to tissue and biologically inert."
				},
				{
					'advan': "Gives strong support for indefinite periods."
				},
				{
					'advan': "Excellent tensile strength and pliability."
				},
				{
					'advan': "Easy retrieval pack."
				},
				{
					'advan': "Easy to draw through tissues."
				},
				{
					'advan': "Does not harbour micro-organisms."
				},
				{
					'advan': "Inert material."
				},
				{
					'advan': "Least thrombogenic."
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 8-0 to 2"
				},
				{
					'range': "Suture length available with needle: 35cms to 150cms."
				},
				{
					'range': "Needle lengths: 6mm to 50mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOTlENE.png"
		},
		"KNOT-STEEL": {
			name: 'KNOT-STEEL',
			sub: [
				{
					'title': "STEEL SUTURES"
				},
				{
					'descrip': "Monofilament Non absorbable LVM 316 steel suture with drilled end curved needle"
				},
				{
					'proDescrip': "KNOT-STEEL, Our Steel Suture is  monofilament and non-absorbable, sterile surgical suture. Alpha-Steel sutures are made up of high quality 316 LVM medical grade stainless steel, the trusted metal for surgical use. KNOT-Steel suture has high tensile strength. Alpha-Steel sutures are specially designed to minimize breakage during band tightening by twisting."
				}
			],
			advantages: [
				{
					'advan': "Silicon coated autramatic needle."
				},
				{
					'advan': "Excellent tensile strength."
				},
				{
					'advan': "Excellent tissue compatibility."
				}
			],
			recUsage: [
				{
					'recusage': "Cardiovascular surgeries (Sternum closure)"
				},
				{
					'recusage': "Orthopaedic surgeries"
				}
			],
			ranges: [
				{
					'range': "U.S.P. sizes 1 to 7"
				},
				{
					'range': "Suture length available with needle: 45cms to 75cms."
				},
				{
					'range': "Needle lengths: 40mm to 48mm attached to various needle point profiles."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/KNOtSTEEL.png"
		},
		"SECU-WAX": {
			name: 'SECU WAX',
			sub: [
				{
					'title': "HEAMOSTATIC BONE WAX"
				},
				{
					'descrip': "Haemostatic Bone Wax for Orthopedic surgical wound management"
				}
			],
			features: [
				{
					'feature': "Helps local haemostasis by functioning like a mechanical barrier."
				},
				{
					'feature': "Bio-chemically inert and minimally resorbable."
				},
				{
					'feature': "It is compatible with body tissues, does not induce skin irritation or any allergic reactions."
				},
				{
					'feature': "Composition: Bees wax I.P.,White Hard Paraffin Wax, I.P., Iso Propyl Palmitate, U.S.P."
				},
				{
					'feature': "Colour : Opaque."
				},
				{
					'feature': "Sterilization: Gamma Radiation."
				},
				{
					'feature': "Shelf Life : 5 years."
				}
			],
			ranges: [
				{
					'range': "Sterile and single use medical device sizes ,Box of 12 pcs , each 2.5 gms"
				}
			],
			imageUrl: "assets/MSImages/woundClosure/SECUwAX.png"
		},
		"OXICEL": {
			name: 'OXICEL',
			sub: [
				{
					'title': "OXIDIZED REGENERATED CELLULOSE"
				}
			],
			recUsage: [
				{
					'recusage': "Cardiac Surgery – valve replacement surgery ,sterna medullary bleeding"
				},
				{
					'recusage': "Gynecological surgeries – Uterine wall perforation"
				},
				{
					'recusage': "General surgeries – Iatrogenic perforations"
				},
				{
					'recusage': "Neurosurgery – operative intervention cerebrum, ventricular system, spinal surgery"
				}
			],
			ranges: [
				{
					'range': "It is a sterile and single use medical product, standard size are 2x3,4x8, 2x14 inches."
				}
			],
			imageUrl: "assets/MSImages/woundClosure/OXICEL.png"
		},
		"ALPHA-HAEMO-SPONGE": {
			name: 'ALPHA HAEMO SPONGE',
			sub: [
				{
					'title': "Haemostatic Sponges"
				},
				{
					'descrip': "Haemostatic collagen based sponge for wound care and management"
				},
				{
					'proDescrip': "HAEMOSPON-HAEMOSTATIC SPONGE, is convenient to use surgical haemostatic sponge made from highly purified gelatin material from natural collagen origin for use in various surgical procedures, where traditional haemostasis is difficult or impractical and use of other non-absorbable material is undesirable. Its safe and efficient wound dressing for usage on highly exuding wounds for better absorption and confer residence time on the wound bed."
				}
			],
			advantages: [
				{
					'advan': "Reduces the bacterial load within the wound and keeps the wound clean."
				},
				{
					'advan': "Fights the local infection and avoids complications."
				},
				{
					'advan': "Is pain-free at removal."
				},
				{
					'advan': "Is well-tolerated and comfortable."
				}
			],
			ranges: [
				{
					'range': " It is a sterile and single use medical product, standard size are 2x2 ,4x4 , 6x 6 , 8x8 inches with varying thickness from 1 mm ,5 mm and 10 mm"
				}
			],
			imageUrl: "assets/MSImages/woundClosure/HEAMOsPON.png"
		},

		"MICROS": {
			name: 'MICROS',
			sub: [
				{
					'title': "Surgical Micro porous Tapes "
				}
			],
			features: [
				{
					'feature': "Micro porous , Hypoallergenic surgical paper tape."
				},
				{
					'feature': "Uniformly coated with acrylic adhesive."
				},
				{
					'feature': "Optimum tack –Ensures superior adhesion."
				},
				{
					'feature': "Pressure sensitive – Application of optimum force for controlled adhesion."
				},
				{
					'feature': "Easy release technology facilitates effortless tape pull out."
				},
				{
					'feature': "Water and vapour repellent."
				},
				{
					'feature': "Suitable for all types of skin and people with allergies."
				},
				{
					'feature': "Secures intravenous needles, electrodes, catheters, cannula and many more."
				},
				{
					'feature': "Superior Quality, highly permeable to air – no risk of maceration."
				},
				{
					'feature': "Effortless, convenient and pain free removal."
				},
				{
					'feature': "No adhesive residue on skin, safe on sensitive body parts."
				}
			],
			imageUrl: "assets/MSImages/Consumable/MICROS.jpeg"
		},

		"SAFEHANS-SURGICAL": {
			name: 'SAFEHANS SURGICAL',
			sub: [
				{
					'title': "Latex Surgical gloves"
				}
			],
			features: [
				{
					'feature': "Anatomical Shape Powder free and Powdered  Surgical Gloves of very low protein: Less than 50 micrograms/dm2."
				},
				{
					'feature': "Coating of polymer enables easy donning, buffers hand from direct contact with latex to reduce the risk of skin irritation."
				},
				{
					'feature': "Micro roughened surface for better instrument gripping."
				},
				{
					'feature': "Anatomical design to reduce hand fatigue."
				},
				{
					'feature': "Low level of latex protein to reduce risk of skin allergic reactions."
				},
				{
					'feature': "Conforms to ASTM D 3577 & EN455 standards."
				},
				{
					'feature': "Manufactured under GMP & ISO 13485:2003 systems."
				},
				{
					'feature': "100% inspected Gloves meeting AQL 1.5 levels."
				},
				{
					'feature': "Available Sizes: 6, 6.5,7, 7.5, 8, 8.5."
				},
				{
					'feature': "Moisture – Content below 0.3% per glove."
				},
				{
					'feature': "Shell-life – 5 years from date of manufacturing."
				}
			],
			imageUrl: "assets/MSImages/Consumable/SAFEHANSSURGICAL.jpeg"
		},

		"SAFEHANS-EXAM": {
			name: 'SAFEHANS EXAM',
			sub: [
				{
					'title': "Latex Examination Gloves"
				}
			],
			features: [
				{
					'feature': "The silky smooth finish of this powdered latex examination glove offers a high degree of comfort and flexibility and enhanced tactile sensitivity while still providing an excellent grip and can easily accommodate the user’s hands. It reduces incidences of skin sensitivity reactions. It is ambidextrous and straight fingers."
				},
				{
					'feature': "As per ASTM 3578 and EN 455-1,2,3, standards sampling plan ISO 2859-1."
				},
				{
					'feature': "Length min:240mm, Cuff Thickness min-0.08."
				}
			],
			imageUrl: "assets/MSImages/Consumable/SAFEHANSeXAM.png"
		},
		"ENWRAP-DRAPE": {
			name: 'ENWRAP DRAPE',
			sub: [
				{
					'title': "HOSPITAL DRAPES"
				},
				{
					'proDescrip': "Our surgical drape options are engineered to meet industry testing criteria. Product packaging is color-coded to indicate AAMI protection level."
				}
			],
			features: [
				{
					'feature': "Blue color for SMS drape fabric helps reduce glare from operating lights."
				},
				{
					'feature': "Provides color contract between drape fabric and reinforced areas for visual definition of critical zone."
				},
				{
					'feature': "Standardized absorbent reinforcement material in critical zones."
				},
				{
					'feature': "Creates a more consistent look, feel and experience for clinicians."
				},
				{
					'feature': "Enhanced reinforcement sizing on select codes."
				},
				{
					'feature': "Fabric anesthesia screen on select codes."
				},
				{
					'feature': "Available in various forms of surgery kits for universal, Laprotomy, Cardiac, orthopedic, etc."
				}
			],
			imageUrl: "assets/MSImages/Consumable/ENWRAPDRAPES.png"
		},
		"ENWRAP-GOWN": {
			name: 'ENWRAP GOWN',
			sub: [
				{
					'title': "Surgical gown"
				}
			],
			features: [
				{
					'feature': "Our Gowns offer high-level protection without compromising on quality & comfort."
				},
				{
					'feature': "Our range of surgical gown SMS, spun melt composites such as BVB (Breathable Viral Barrier) and other film-based composites."
				},
				{
					'feature': "Easy to wear for prolonged surgeries and meet all demands of surgical use."
				},
				{
					'feature': "The soft knit cuffs are comfortable but their length facilitates closed gloving."
				},
				{
					'feature': "Compatible with ethylene oxide, steam, and gas plasma sterilization."
				}
			],
			imageUrl: "assets/MSImages/Consumable/ENWRAPGOWN.jpg"
		},

		"IV-FIX": {
			name: 'IV-FIX',
			sub: [
				{
					'title': "IV fixation dressing – Non woven and Transparent material"
				}
			],
			features: [
				{
					'feature': "Used for Fixation of I.V Cannula / Catheter."
				},
				{
					'feature': "Made from Thin Polyurethane film and available in two variants -Transparent and Non-woven."
				},
				{
					'feature': "Breathable and water proof, with reinforced notches that reduce  mechanical stress from heavy catheters."
				},
				{
					'feature': "Thin film ensures easy fixation of devices with contoured surfaces."
				}
			],
			imageUrl: "assets/MSImages/Consumable/IVFIX.jpg"
		},
		"ESCU-BANDAGE": {
			name: 'ESCU BANDAGE',
			sub: [
				{
					'title': "Bandages for Surgical dressing"
				}
			],
			features: [
				{
					'feature': "A wide range of surgical bandage roll that are hygienically made so that they are safe to use in various medical applications."
				},
				{
					'feature': "We produce surgical bandages of various sizes according to customer requirement."
				},
				{
					'feature': "Surgical bandages edges are evenly cut parallel to warp threads and are reasonably free from loose threads."
				},
				{
					'feature': "Surgical bandages are used for different types of cuts, injuries, wounds etc , It is Strong and convenient when moderate compression is required."
				}
			],
			imageUrl: "assets/MSImages/Consumable/ESCUBAND.png"
		},
		"ESCU-GAUZE": {
			name: 'ESCU GAUZE',
			sub: [
				{
					'title': "Surgical Gauze"
				}
			],
			features: [
				{
					'feature': "100 % cotton; X-ray detectable thread: blue, polypropylene, polyester thread, and barium sulphate types for different application."
				},
				{
					'feature': "Gauze Swabs are soft, absorbent and air-permeable."
				},
				{
					'feature': "Cut edges are inside and there is no fraying."
				},
				{
					'feature': "No loose threads and good absorbency."
				}
			],
			imageUrl: "assets/MSImages/Consumable/ESCUGUAZE.png"
		},
		"CONCEAL-MASK": {
			name: 'CONCEAL MASK',
			sub: [
				{
					'title': "SURGICAL FACE MASK 3 PLY"
				}
			],
			features: [
				{
					'feature': "3 ply face mask with meltbown and nose pin and in medical blue color."
				},
				{
					'feature': "Ear Loop ultrasonic punching has excellent attach strength."
				},
				{
					'feature': "Elastic length - 7 inch each side."
				},
				{
					'feature': "20 gsm + 25gsm + 20gsm ( blue + white + blue )."
				},
				{
					'feature': "1st layer - spun bond polypropylene 20 gsm."
				},
				{
					'feature': "2nd layer - meltbown polypropylene 25 gsm."
				},
				{
					'feature': "3rd layer - spun bond polypropylene 20 gsm."
				},
				{
					'feature': "Lenght - 7 inch , width - 4 inch."
				},
				{
					'feature': "Confirms with ASTM D 3577  and EN 455 standards."
				}
			],
			imageUrl: "assets/MSImages/Consumable/CONCEALMASK.png"
		},
		"CONSHIELD": {
			name: 'CONSHIELD',
			sub: [
				{
					'title': "WOUND SHIELD COVERS"
				}
			],
			features: [
				{
					'feature': "Keep you catheter site, PICC line, central ports and dressed wounds covered and dry."
				},
				{
					'feature': "Prevent infection and reduce dressing changes with shield cover."
				},
				{
					'feature': "Its  a single use product."
				},
				{
					'feature': "Sizes 4x4 inch , 7x7 inch , 9x9 inch , 10x12 inch."
				},
				{
					'feature': "Pack of 10 pieces."
				}
			],
			imageUrl: "assets/MSImages/Consumable/CONSHIELD.jpeg"
		},
		"CLENSOR-WIPES": {
			name: 'CLENSOR WIPES',
			sub: [
				{
					'title': "Alcoholic wipes"
				},
				{
					'proDescrip': "Alcohol wipes are individually wrapped pads made of a cotton-type material and saturated with 70 percent isopropyl alcohol. They are tightly sealed in an aluminum paper packet that keeps them sterile and prevents them from drying out."
				},
				{
					'proDescrip': "Alcohol wipes can be used to clean the skin when soap and water are not available. They can be used to clean small cuts, scrapes and bug bites as well as new blisters."
				}
			],
			imageUrl: "assets/MSImages/Consumable/CLENSORWIPES.png"
		},
		"CLENSOR-SANITIZER": {
			name: 'CLENSOR SANITIZERS ',
			sub: [
				{
					'title': "Alcohol based hand sanitizer"
				}
			],
			features: [
				{
					'feature': "Hand sanitizing rub protects from germs without the use of soap & water."
				},
				{
					'feature': "It has 70 % (v/v) Alcohol Content making it effective against the germs on the go."
				},
				{
					'feature': "Protection from the germs transferred from direct contact with an infected person or from an external source."
				},
				{
					'feature': "Rinse free & Non Sticky."
				},
				{
					'feature': "Usage Direction : Apply (approx 3 ml )on your plams, spread and rub well over the palms, back of hands, Fingertips gently until dry."
				}
			],
			imageUrl: "assets/MSImages/Consumable/CLENSORSANITIZER.png"
		},
		"CATHA-WAY": {
			name: 'CATHA WAY',
			sub: [
				{
					'title': "Central Venous Catheter"
				},
				{
					'descrip': "Central Venous Catheter in single, double and triple types"
				}
			],
			features: [
				{
					'feature': "Geometrically designed soft and conical tip of catheter ensures safe and easy insertion."
				},
				{
					'feature': "Offers good tissue and blood tolerance thus reduces the risk of blood clotting & intra vascular pain."
				},
				{
					'feature': "Exceptionally bio-compatible PU material which by property is stiff & non-kinking at room temperature but softens at body temperature thus ensures correct placement of catheter."
				},
				{
					'feature': "Soft & flexible tip of guide wire with excellent stiffness offers good torque which helps in easy insertion and prevents vessel perforation."
				},
				{
					'feature': "Clear and transparent radio-opaque material of lumen with definite marking and color coding ensures accurate positioning and minimum vessel damage."
				}
			],
			imageUrl: "assets/MSImages/INFUSIONS/CATHAWAY.png"
		},
		"SPINE-WAY": {
			name: 'SPINE WAY',
			sub: [
				{
					'title': "Spinal Needle"
				},
				{
					'descrip': "High quality Spinal needle of varying sizes designed for special necessities of infusion therapy, ensures pain-free & smooth penetration"
				}
			],
			features: [
				{
					'feature': "Sizes : G-18, 20, 21, 22, 23, 24, 25, 26, 27."
				},
				{
					'feature': "Specially designed to administer lumbar/ subarachnoid anesthesia."
				},
				{
					'feature': "Clear polycarbonate hub offers easy visualization."
				},
				{
					'feature': "Transparent hub provides rapid detection of Cerebro-spinal fluid (CSF) flashback for confirming accurate placement."
				},
				{
					'feature': "Sharp bevel design for low puncture force ensures minimal puncture trauma."
				},
				{
					'feature': "Fine gauze needle design greatly reduces the risk of PDPH (Post Dura Puncture Headache)."
				},
				{
					'feature': "Conical tip imparts superior tactile feedback."
				},
				{
					'feature': "Color coded stylet enables rapid size identification."
				},
				{
					'feature': "High flow rate enables faster CSF flashback."
				},
				{
					'feature': "Aggressive anesthetic distribution upon injection."
				},
				{
					'feature': "Snugly fit stylet into the respective needle facilitates no tissue coring."
				}
			],
			imageUrl: "assets/MSImages/INFUSIONS/SPINEWAY.jpg"
		},
		"CANU-WAY": {
			name: 'CANU WAY',
			sub: [
				{
					'title': "IV Cannula"
				},
				{
					'descrip': "High quality IV Cannula of varying sizes designed for special necessities of infusion therapy; ensures pain-free & smooth penetration."
				}
			],
			features: [
				{
					'feature': "Non-pyrogenic and the flash back chamber allows quick visualization of venous return."
				},
				{
					'feature': "Wings specially designed for easy gripping and safe clamping."
				},
				{
					'feature': "Needle cover to prevent accidental damage to needle & catheter."
				},
				{
					'feature': "Color coded body/port cap as per ISO Standard."
				},
				{
					'feature': "6% luer taper as per International standard."
				},
				{
					'feature': "Stainless steel (AISI 304) needle with diameter and length adhering to all ISO standards."
				},
				{
					'feature': "Triple point Needle for painless insertion."
				},
				{
					'feature': "Thin walled, Radio-opaque stripped / transparent Teflon catheter, as per USP standard Class VI."
				},
				{
					'feature': "Customized Automated Tipping Technology for lower penetration forces Optimum trim distance for pain free penetration."
				}
			],
			imageUrl: "assets/MSImages/INFUSIONS/CANUWAY.jpg"
		},
		"IV-WAY": {
			name: 'IV WAY',
			sub: [
				{
					'title': "IV Infusion Set"
				},
				{
					'descrip': "High quality IV Infusion set of varying sizes designed for special necessities of infusion therapy, ensures pain-free & smooth penetration."
				}
			],
			features: [
				{
					'feature': "Conforms to international standards."
				},
				{
					'feature': "Special beveled extra-strong spike."
				},
				{
					'feature': "Flexible drip chamber facilitates rapid adjustment of fluid."
				},
				{
					'feature': "Disc filter in chamber ensures filtration of administered fluid."
				},
				{
					'feature': "Highly kink-resistant tubing."
				},
				{
					'feature': "Roller clamp for flow control."
				},
				{
					'feature': "Self-sealing latex tube for extra medication."
				},
				{
					'feature': "Easy flushing."
				}
			],
			imageUrl: "assets/MSImages/INFUSIONS/IVWAY.jpg"
		},
		"INJECT-WAY": {
			name: 'INJECT WAY',
			sub: [
				{
					'title': "Syringe"
				},
				{
					'descrip': "High quality single use Auto disable syringes with needles."
				}
			],
			features: [
				{
					'feature': "Luer lock Single use syringes with or without needle."
				},
				{
					'feature': "Secure fitment of needle due to presence of luer lock."
				},
				{
					'feature': "High clarity plunger and barrel."
				},
				{
					'feature': "Better plunger retention due to locking at the base."
				},
				{
					'feature': "Compatible with all medications."
				},
				{
					'feature': "Silconized needle , minimal pain."
				}
			],
			imageUrl: "assets/MSImages/INFUSIONS/INJECTwAY.jpg"
		},

		"FO-CATH-2-WAY": {
			name: 'FO-CATH 2 way',
			sub: [
				{
					'title': "Urinary Foley catheter 2 way"
				},
				{
					'proDescrip': "FO-CATH 2 way foley catheter  is convenient to use silicon coating ensures painless insertion and adhesion-free deployment , its excellent balloon symmetry for comfortable anchorage and patient comfort , Our foley Catheter has Polypropylene Non Return Valve enables easy handling  and smooth tapered tip for easy introduction to minimize urethral trauma , Also Available in pediatric sizes with style wire."
				}
			],
			ranges: [
				{
					'range': "It is a sterile and single use medical device ,Available sizes: Fr6 – Fr22"
				}
			],
			imageUrl: "assets/MSImages/UROLOGY/FOCATH3WAY.png"
		},
		"FO-CATH-3-WAY": {
			name: 'FO-CATH 3 way',
			sub: [
				{
					'title': "Urinary Foley catheter 3 way"
				},
				{
					'proDescrip': "FO-CATH 3 way foley catheter  is convenient to use silicon coating ensures painless insertion and adhesion-free deployment , its excellent balloon symmetry for comfortable anchorage and patient comfort , Our foley Catheter has Polypropylene Non Return Valve enables easy handling  and smooth tapered tip for easy introduction to minimize urethral trauma , Also Available in pediatric sizes with style wire."
				}
			],
			ranges: [
				{
					'range': "It is a sterile and single use medical device ,Available sizes: Fr6 – Fr22."
				}
			],
			imageUrl: "assets/MSImages/UROLOGY/FOCATH2WAY.jpg"
		},
		"URO-SHEATH": {
			name: 'URO-SHEATH',
			sub: [
				{
					'title': "Urinary sheath"
				},
				{
					'proDescrip': "URO  Sheath is Secure ,Easy to use, latex free and transparent to observe skin integrity promote a secure and comfortable solution for draining the bladder Supplied in a wide range of sizes and styles,URO SHEATH provide patients and healthcare professionals with a choice and confidence to select the right product to meet patient’s needs."
				}
			],
			ranges: [
				{
					'range': "It is a sterile and single use medical device, Available in three styles: Standard, Pop On and Wide Band. Each style is available in five sizes: 24mm, 28mm, 31mm, 35mm, 40mm."
				}
			],
			imageUrl: "assets/MSImages/UROLOGY/UROSEATH.jpg"
		},
		"URO-DRAIN-BAG": {
			name: 'URO-DRAIN BAG',
			sub: [
				{
					'title': "Urinary drainage bag"
				},
				{
					'proDescrip': "ALPHA Urinary drainage bag range of drainage bags has been designed to help minimize the risks to both patients and their carers; Its Unique Click-Close drainage tap  helps patients and carers easily identify when the tap is open and closed , there’s a Stepped connector allows a safe and secure connection preventing leakage and accidental disconnection . Our Urinary Bag also has Needle-free sample port facilitates safe and efficient risk-free sampling  and the quick-dry backing helps patients remain comfortable and confident when wearing their leg bags ,The anti-reflux valve in all bags prevents urine backflow and our high quality medical grade PVC keeps unwelcome aroma to a minimum."
				}
			],
			ranges: [
				{
					'range': "It is a sterile and single use medical device,available in sizes: 350 ml, 500 ml  and 750 ml."
				}
			],
			imageUrl: "assets/MSImages/UROLOGY/UROdRAINbAG.jpg"
		},
		"URO-COLLECT-BAG": {
			name: 'URO- COLLECT BAG',
			sub: [
				{
					'title': "Urinary Metered collection Bag"
				},
				{
					'proDescrip': "Urinary Metered collection bag has been thoughtfully designed and made to ensure high quality treatment for patients and ease of use for healthcare professionals.It is sterile and latex free comes in various sizes and has T-tap kennel protects the tap from external bacteria, It alps has Long Kink resistant tubing and flexible hanging options for diverse bed designs."
				}
			],
			ranges: [
				{
					'range': "It is a sterile and single use medical device, it is available in 400ml capacity with 2600ml drainage bag."
				}
			],
			imageUrl: "assets/MSImages/UROLOGY/UROcOLLECTbAG.jpg"
		},
		"CAREPEE": {
			name: 'CAREPEE',
			sub: [
				{
					'title': "Urinating device for women"
				},
				{
					'proDescrip': "Urinary Metered collection bag has been thoughtfully designed and made to ensure high quality treatment for patients and ease of use for healthcare professionals.It is sterile and latex free comes in various sizes and has T-tap kennel protects the tap from external bacteria, It alps has Long Kink resistant tubing and flexible hanging options for diverse bed designs."
				}
			],
			features: [
				{
					'feature': "Medical-grade silicone reusable urinating device for women."
				},
				{
					'feature': "Splash guard eliminates messing and spilling."
				},
				{
					'feature': "Urinate while standing up , encouraging to maintain personal hygiene."
				},
				{
					'feature': "Fits easily in your purse, pocket, etc excellent for everyday use when trying to cover up an unsanitary public toilet."
				}
			],
			imageUrl: "assets/MSImages/UROLOGY/CAREpEE.png"
		},

		"HAEMO-CLEANSE": {
			name: 'HAEMO CLEANSE',
			sub: [
				{
					'title': "HIGH AND LOW FLUX DIALYSER"
				},
				{
					'descrip': "High-quality haemodialyser for all types of dialysis machines"
				},
			],
			features: [
				{
					'feature': "High-performance dialyser housed in medical-grade polycarbonate."
				},
				{
					'feature': "Performance-enhanced technology with high biocompatibility."
				},
				{
					'feature': "Caps for blood inlet and outlet ports for safer storage."
				},
				{
					'feature': "Caps for dialysate inlet and outlet for safer storage."
				},
				{
					'feature': "High-quality sterilisation procedure using gamma radiation."
				},
				{
					'feature': "Openable ends for easy cleaning."
				},
				{
					'feature': "Available in both High and Low Flux."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/HAEMOcLEANSE.jpeg"
		},
		"CATHALINE-HD": {
			name: 'CATHALINE HD',
			sub: [
				{
					'title': "HAEMODIALYSIS CATHETER"
				},
				{
					'descrip': "Haemodialysis Catheter in straight and curved types"
				},
			],
			features: [
				{
					'feature': "Used for exchanging  blood to and from the hemodialysis machine from the patient."
				},
				{
					'feature': "Made of Bio-compatable polyurethane."
				},
				{
					'feature': "Radiopaque for better visibility."
				},
				{
					'feature': "Available in double lumen straight and curved type."
				},
				{
					'feature': "Full set has Catheter , guode wire , vessel dilator ,Introducer needle ,Injector caps ,syringe with needle , scalpel blade , material sheet."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/CATHALINEhD.jpg"
		},
		"HAEMO-LINE": {
			name: 'HAEMO LINE',
			sub: [
				{
					'title': "HAEMODIALYSIS BLOOD LINE SET"
				},
				{
					'descrip': "High-quality arterial and venous blood tubing set for all dialysis machines"
				},
			],
			features: [
				{
					'feature': "Pre-pump and post-pump tubing sets options."
				},
				{
					'feature': "Special design pinch clamp to perform perfectly."
				},
				{
					'feature': "Parts of superior quality, unique design and high-performance with flow rate maintained consistently."
				},
				{
					'feature': "Latex-free injection site with large dia needle access port."
				},
				{
					'feature': "Smooth inner surface reduces foaming and facilitates removal of air."
				},
				{
					'feature': "Guarded access ports for reduced infection risks."
				},
				{
					'feature': "Colour-coded clamps and dialyser connectors for easy identification."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/HAEMOLINE.jpg"
		},
		"AV-FIST": {
			name: 'AV FIST',
			sub: [
				{
					'title': "AV FISTULA NEEDLE"
				},
				{
					'descrip': "High-quality arterial and venous fistula needle for haemodialysis dialysis"
				},
				{
					'proDescrip': "The AV fistula needle is made from kink-resistant soft PVC. It is available in sterile twin-pack pouches. The ultra-thin-walled stainless steel needle with back-eye has siliconised material both on the internal as well as external surfaces."
				}
			],
			features: [
				{
					'feature': "Ultra-thin-walled stainless steel needle with back-eye for smooth penetration."
				},
				{
					'feature': "Special process on blade reduces pain and skin damage for smoother penetration."
				},
				{
					'feature': "Medical-grade PVC tubing minimizes side-effects and protects health."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/AVfIST.jpg"
		},
		"DIACATE": {
			name: 'DIACATE  CONCENTRATES ',
			sub: [
				{
					'title': "Acetic Acid and Citric Acid PART A"
				},
				{
					'descrip': "Acetic and Citric acids acid concentrate fluids for haemodialysis –PART A"
				}
			],
			features: [
				{
					'feature': "Acetic and Citrate acid concentrate in dry and fluid form."
				},
				{
					'feature': "Raw materials and Finished products are tested monograph laid as per British Pharmacopoeia (B.P)."
				},
				{
					'feature': "A fully equipped in-house lab to ensure the correctness of the chemicals."
				},
				{
					'feature': "Principal formulas of Haemodialysis concentrates listed below are available in 05, 10, 30,liters canister."
				},
				{
					'feature': "Able to customize specific formula on request other than listed below."
				},
				{
					'feature': "Concentration of electrolytes is given in millimoles/liter."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/DIACATE.png"
		},
		"DIACARB": {
			name: 'DIACARB',
			sub: [
				{
					'title': "BICARBONATE POWDER PART B"
				},
				{
					'descrip': "Haemodialysis Bicarbonate Bag - Part B"
				},
				{
					'proDescrip': "Each Haemodialysis Bicarbonate Bag contains finest quality Sodium Bicarbonate of applicable standard (B.P, E.P, USP) and specified weight. Connector design, shape and size of Haemodialysis Bicarbonate Bag is such that it readily gets plugged in to the slot provided in dialysis machine."
				}
			],
			features: [
				{
					'feature': "Hygienic delivery of Sodium Bicarbonate."
				},
				{
					'feature': "Easy to handle – It eliminates laborious handling of Bicarbonate carboys."
				},
				{
					'feature': "Less storage space required."
				},
				{
					'feature': "Environment friendly."
				},
				{
					'feature': "Pack Sizes-500gm, 650gm, 720gm, 900gm."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/DIAcARB.jpeg"
		},
		"DIAPREP": {
			name: 'DIA PREP KIT',
			sub: [
				{
					'title': "ON/OFF KIT  for haemodialysis procedures"
				},
				{
					'proDescrip': "On/Off Kit for Haemodialysis has been designed to help the medical technicians, nurses and ward staff to address patient needs in a professional and hygienic manner. It provides your dialysis centre the advantages of increased compliance, eliminating wastage, increasing staff efficiency and conserving inventory.."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/DIApREP.jpg"
		},
		"CONCEAL-CATH ": {
			name: 'CONCEAL CATH',
			sub: [
				{
					'title': "HD Catheter covers"
				}
			],
			features: [
				{
					'feature': "Designed to securely house and protect a Central Venous Catheter during showering."
				},
				{
					'feature': "Provides a watertight seal for protection from water when showering."
				},
				{
					'feature': "Additional adhesive strips to secure the pouch to the skin."
				},
				{
					'feature': "Minimizes the potential risk from water borne infections."
				},
				{
					'feature': "Clear medical film; aids positioning and provides visual reassurance of the integrity of the pouch."
				},
				{
					'feature': "Soft and flexible."
				},
				{
					'feature': "Designed for easy application and removal."
				},
				{
					'feature': "Latex free."
				},
				{
					'feature': "Available in three sizes: Standard, Extra Large & Small."
				}
			],
			imageUrl: "assets/MSImages/HAEMODIALYSIS/"
		},

	}

	constructor(private _activatedRoute: ActivatedRoute) {

		this.getParam();
	}

	public ngOnInit() {
		this.mySubProduct = this.subProduct[this.product]
		$(document).ready(function () {
			$(this).scrollTop(0);
		});
		console.log("my Sub Product list ", this.mySubProduct, this.subProduct);

	}

	private getParam() {
		this._activatedRoute.params.subscribe(
			(param) => {
				this.product = param['type']
				console.log('producttype', this.product);
			}
		)
	}
}
