import { Component, ViewEncapsulation, OnInit } from '@angular/core';

declare var $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
	title = 'mnssci';

	public ngOnInit() {
		$(function () {
			$(window).on('scroll', function () {
				if ($(window).scrollTop() > 10) {
					$('.navbar').addClass('active');
				} else {
					$('.navbar').removeClass('active');
				}
			});
		});
		$(document).ready(function () {
			$(this).scrollTop(0);
		});
	}
}
