<div class="container col-12 bg-images-haemodialysis">
    <div class="overlay-haemodialysis"></div>
</div>

<div class="container col-12 col-md-12 col-lg-10 col-xl-10">
    <div class="row m-2">
        <div class="col-1">
            <div>
                <img class="float-right" src="../assets/images/iconHaeHae.png" alt="Haemodialysis icon"
                    style="height: 2.4rem;" />
            </div>
        </div>
        <div class="col-11 p-0 m-0 fontMontserrat">
            <div class="py-2">
                <b>Haemodialysis</b>
            </div>
            <div class="mb-5">
                <p class="text-justify">
                    MANSAR SCIENTIFC brings you the widest, fully certified range of dialysis products: Dialyser,
                    Bloodline set, AV fistula needle, Transducer protector, CV catheter, Heparin, Bi-carbonate, On/Off
                    kit, and Sterilant in multiple specifications to match your needs.
                </p>
            </div>
        </div>
    </div>
</div>

<!-- <div id="boxTabs">
    <div class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5"> -->
<!-- Nav tabs -->
<!-- <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#absorbableSutures">Surgical Sutures</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#herniaSolution">Hernia Solution</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#skinStaplers">Skin Staplers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tissueAdhesive">Tissue Adhesive</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#haemostats">Haemostats</a>
            </li>
        </ul> -->
<!-- Tab panes -->
<!-- <div class="tab-content">
            <div id="absorbableSutures" class="container tab-pane active border rounded">
                <br />
                <div class="vl p-2">
                    <b>Absorbable Sutures</b>
                </div>

                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-CRYIL</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTcRIYIL.png" alt="Card image cap"
                                            style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-CRYIL FAST</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTcRYILfAST.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-MONO</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtMONO.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-PDO</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtPDO.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-GUT CHROMIC</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTgUTcHROMIC.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-GUT PLAIN</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTgUTpLAIN.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div id="herniaSolution" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Hernia Solution</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT LENE-MESH</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTLENEmESH.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT LENE-COMP MESH</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOtSROBmESH.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div id="skinStaplers" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Skin Staplers</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-8 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">ESSEN-STAPLER</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/ESSENsKINsTAPLER.jpg"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tissueAdhesive" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Tissue Adhesive</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">SKIN GLUE</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/SKINgLUE.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="haemostats" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Haemostats</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">HAEMO WAX</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/SECUwAX.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">OXICEL</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/OXICEL.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">ALPHA HAEMO SPONGE</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
<!-- </div>
</div> -->

<!-- // woundClosure list here // -->
<!-- <div class="col-12">

	<div *ngFor="let item of products">

		<div class="col-12 ">
			<label>{{ item.name}}</label>
		</div>

		<div class="w-100">
			<a class="w-100 " routerLink="/products/{{item.name}}">{{item.image}}</a>
		</div>

	</div>

</div> -->

<div id="haemodialysisProducts" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5">
    <div class="container tab-pane active border rounded">
        <br />
        <div class="vl p-2">
            <b>Haemodialysis Products</b>
        </div>

        <div class="col-12 py-3">
            <div class="row">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">HAEMO CLEANSE</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/HAEMOcLEANSE.jpeg"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/HAEMO-CLEANSE">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">CATHALINE HD</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/CATHALINEhD.jpg"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/CATHALINE-HD">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">HAEMO LINE </label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/HAEMOLINE.jpg"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/HAEMO-LINE">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">AV FIST</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/AVfIST.jpg"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/AV-FIST">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">DIACATE</label>
                            </div>
                            <div class="card-body">
                                <div class="d-flex justify-content-center align-items-center">
                                    <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/DIACATE.png"
                                        alt="Card image cap" style="height: 8rem; width: auto;">
                                </div>
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/DIACATE">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">DIACARB</label>
                            </div>
                            <div class="card-body">
                                <div class="d-flex justify-content-center align-items-center">
                                    <img class="card-img-top " src="../../assets/MSImages/HAEMODIALYSIS/DIAcARB.jpeg"
                                        alt="Card image cap" style="height: 8rem; width: auto;">
                                </div>
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/DIACARB">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">DIAPREP</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/DIApREP.jpg"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/DIAPREP">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">CONCEAL CATH</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/HAEMODIALYSIS/"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <a routerLink="/products/CONCEAL-CATH">
                                        <label class="m-0 colView">View More</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>