<!-- <p>products works!</p> -->

<div class="container col-12 bg-images-product">
	<div class="overlay-product"></div>
</div>

<div class="container col-12 col-md-12 col-lg-10 col-xl-10">
	<div class="row">
		<div class="col-1">
			<div>
				<img class="float-right" src="../assets/images/iconWoundClosure.png" alt="WoundClosure icon"
					style="height: 2.4rem;" />
			</div>
		</div>
		<div class="col-11 p-0 m-0 fontMontserrat">
			<div class="py-2">
				<b>Wound Closure</b>
			</div>
			<div class="mb-5">
				<p class="text-justify">
					MANSAR SCIENTIFIC brings you the widest fully certified range of wound
					closure products: Absorbable and non Absorbable sutures, hernia mesh ,
					Tissue adhesives , Hemostats and skin stapler in multiple
					specifications to match your needs.
				</p>
			</div>
		</div>
	</div>
</div>

<div id="boxTabs">
	<div class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5">
		<!-- Nav tabs -->
		<ul class="nav nav-tabs" role="tablist">
			<li class="nav-item">
				<a class="nav-link active" data-toggle="tab" href="#home">Surgical Sutures</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#menu1">Hernia Solution</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#menu2">Skin Staplers</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#menu3">Tissue Adhesive</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#menu4">Heamostatst</a>
			</li>
		</ul>

		<!-- Tab panes -->
		<div class="tab-content">
			<div id="home" class="container tab-pane active border rounded">
				<br />
				<div class="vl p-2">
					<b>Absorbable Sutures</b>
				</div>

				<div class="col-12 py-3">
					<div class="row">
						<div class="row">
							<div class="col-3 p-2">
								<div class="card">
									<div class="card-header text-center">
										<label class="m-0 text-white">Trusynth Plus</label>
									</div>
									<div class="card-body">
										<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
											alt="Card image cap" style="height: 8rem;">
										<div class="text-center bg-Color py-1 rounded">
											<label class="m-0 colView">View More</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-3 p-2">
								<div class="card">
									<div class="card-header text-center">
										<label class="m-0 text-white">Trusynth Plus</label>
									</div>
									<div class="card-body">
										<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
											alt="Card image cap" style="height: 8rem;">
										<div class="text-center bg-Color py-1 rounded">
											<label class="m-0 colView">View More</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-3 p-2">
								<div class="card">
									<div class="card-header text-center">
										<label class="m-0 text-white">Trusynth Plus</label>
									</div>
									<div class="card-body">
										<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
											alt="Card image cap" style="height: 8rem;">
										<div class="text-center bg-Color py-1 rounded">
											<label class="m-0 colView">View More</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-3 p-2">
								<div class="card">
									<div class="card-header text-center">
										<label class="m-0 text-white">Trusynth Plus</label>
									</div>
									<div class="card-body">
										<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
											alt="Card image cap" style="height: 8rem;">
										<div class="text-center bg-Color py-1 rounded">
											<label class="m-0 colView">View More</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div id="menu1" class="container tab-pane fade p-0 m-0">
				<br />

				<div class="scrolling-wrapper">
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
					<div class="card col-4 m-2 p-0">
						<h5 class="card-header">Featured</h5>
						<div class="card-body">
							<h5 class="card-title">Special title treatment</h5>
							<p class="card-text">
								With supporting text below as a natural lead-in to
								additional content.
							</p>
							<a href="#" class="btn btn-primary">Go somewhere</a>
						</div>
					</div>
				</div>
			</div>
			<div id="menu2" class="container tab-pane fade">
				<br />

				<!-- <h3>Menu 2</h3>
				<p>
					Sed ut perspiciatis unde omnis iste natus error sit voluptatem
					accusantium doloremque laudantium, totam rem aperiam.
				</p> -->
			</div>
			<div id="menu3" class="container tab-pane fade">
				<br />
				<h3>Menu 2</h3>
				<p>
					Sed ut perspiciatis unde omnis iste natus error sit voluptatem
					accusantium doloremque laudantium, totam rem aperiam.
				</p>
			</div>
			<div id="menu4" class="container tab-pane fade">
				<br />
				<h3>Menu 2</h3>
				<p>
					Sed ut perspiciatis unde omnis iste natus error sit voluptatem
					accusantium doloremque laudantium, totam rem aperiam.
				</p>
			</div>
		</div>
	</div>
</div>



<div class="col-12">

	<div *ngFor="let item of products">

		<div class="col-12 ">
			<label>{{ item.name}}</label>
		</div>

		<div class="w-100">
			<a class="w-100 " routerLink="/products/{{item.name}}">{{item.image}}</a>
		</div>

	</div>

</div>

<!-- <div id="nonAbsorbableSutures" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5">
	<div class="container tab-pane active border rounded">
		<br />
		<div class="vl p-2">
			<b>Non - Absorbable Sutures</b>
		</div>

		<div class="col-12 py-3">
			<div class="row">
				<div class="row">
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3 p-2">
						<div class="card">
							<div class="card-header text-center">
								<label class="m-0 text-white">Trusynth Plus</label>
							</div>
							<div class="card-body">
								<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
									alt="Card image cap" style="height: 8rem;">
								<div class="text-center bg-Color py-1 rounded">
									<label class="m-0 colView">View More</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->