import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {


	public products = [

		{
			name: 'A',
			image: 'a',
			description: 'aaaa'
		},
		{
			name: 'B',
			image: 'b',
			description: 'bbb'
		},
		{
			name: 'C',
			image: 'c',
			description: 'cccc'
		}
	]

	constructor() { }


	public ngOnInit(){

		this.products.forEach((data)=> {
			console.log('Produc Dataa', data);
		},
		(error) => {
			console.log("Error", error);
		}
		
		)
	}

}
