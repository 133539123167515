<!-- <p>view-product works!</p> -->
<div class="container col-12 bg-images-viewProduct">
	<div class="overlay-viewProduct"></div>
</div>

<div class="container col-12 col-md-12 col-lg-10 col-xl-10 ViewProductLabel">
	<div class="row" *ngIf="mySubProduct">

		<!-- <div class="col-12" *ngIf="mySubProduct">
			<label class="w-100">{{mySubProduct.name}}</label>
			<label *ngIf="mySubProduct.sub">
				<label class="w-100" *ngFor="let item of mySubProduct.sub">
					<label class="w-100">{{item.name}}</label>
					<label class="w-100">{{item.title}}</label>
					<label class="w-100">{{item.descrip}}</label>
					<label class="w-100">{{item.proDescrip}}</label>
				</label>
			</label>
		</div> -->

		<div class="col-12">
			<div>
				<!-- <h4>KNOT-CRYIL </h4> -->
				<h4>{{mySubProduct.name}}</h4>
			</div>
			<div *ngIf="mySubProduct.sub">
				<div *ngFor="let item of mySubProduct.sub">
					<!-- <h6>POLYGLACTIN 910 SUTURES</h6> -->
					<h6>{{item.title}}</h6>
					<!-- <strong>Braided synthetic absorbable Polyglactin 910 violet or undyed sutures with drilled end curved
					needles
				</strong> -->
					<strong>{{item.descrip}}</strong>
				</div>
			</div>
		</div>
		<div class="col-12 d-flex justify-content-center py-3" *ngIf="mySubProduct">
			<img src="{{mySubProduct.imageUrl}}" alt="{{mySubProduct.name}}" style="height: 15rem;">
		</div>
		<div class="col-12" *ngIf="mySubProduct.sub">
			<!-- <p>KNOT -CRYIL , Our Polyglactin 910 suture are known for their high quality and undoubted performance.
				Made-up of braided and coated 90% Polyglycolic acid and 10% lactic acid, these are superior in
				absorption with shelf life of 5 years. Available in convenient and safe packaging, these sutures are
				appreciated by our most customers.</p> -->
			<p *ngFor="let item of mySubProduct.sub">{{item.proDescrip}}</p>
		</div>

		<div id="advantageProduct" class="col-12">
			<div class="row" *ngIf="mySubProduct.advantages">
				<div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
					<strong>
						ADVANTEGES :
					</strong>
				</div>
				<div class="listAbout">
					<ul class="p-0">
						<ol class="py-2" *ngFor="let item of mySubProduct.advantages">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							<label>{{item.advan}}</label>
							<!-- High tensile strength. -->
						</ol>
						<!-- <ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Excellent knotting.
						</ol>
						<ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Smooth passage through tissue.
						</ol>
						<ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Silicon coated atraumatic needle.
						</ol>
						<ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Easy retrieval pack.
						</ol> -->
					</ul>
				</div>
			</div>

			<div class="row" *ngIf="mySubProduct.absProfile">
				<div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
					<strong>
						ABSORPTION PROFILE:
					</strong>
				</div>
				<div class="listAbout">
					<ul class="p-0">
						<ol class="py-2" *ngFor="let item of mySubProduct.absProfile">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							<label>{{item.absPro}}</label>
							<!-- Wound Support: 27-30 Days. -->
						</ol>
						<!-- <ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Mass Absorption: 56-70 Days.
						</ol> -->
					</ul>
				</div>
			</div>

			<div class="row" *ngIf="mySubProduct.recUsage">
				<div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
					<strong>
						RECOMMENDED USAGE:
					</strong>
				</div>
				<div class="listAbout">
					<ul class="p-0">
						<ol class="py-2" *ngFor="let item of mySubProduct.recUsage">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							<label>{{item.recusage}}</label>
							<!-- U.S.P Sizes available: 6-0 to 2.$ -->
						</ol>
						<!-- <ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Suture length available with needle: 45cms to 180cms.
						</ol>
						<ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Needle lengths: 12mm to 50mm across all needle point profiles.
						</ol> -->
					</ul>
				</div>
			</div>

			<div class="row" *ngIf="mySubProduct.features">
				<div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
					<strong>
						FEATURES:
					</strong>
				</div>
				<div class="listAbout">
					<ul class="p-0">
						<ol class="py-2" *ngFor="let item of mySubProduct.features">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							<label>{{item.feature}}</label>
						</ol>
					</ul>
				</div>
			</div>

			<div class="row" *ngIf="mySubProduct.ranges">
				<div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
					<strong>
						RANGE:
					</strong>
				</div>
				<div class="listAbout">
					<ul class="p-0">
						<ol class="py-2" *ngFor="let item of mySubProduct.ranges">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							<label>{{item.range}}</label>
							<!-- U.S.P Sizes available: 6-0 to 2.$ -->
						</ol>
						<!-- <ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Suture length available with needle: 45cms to 180cms.
						</ol>
						<ol class="py-2">
							<img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
								style="height: 1.5rem;" />
							Needle lengths: 12mm to 50mm across all needle point profiles.
						</ol> -->
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div id="otherAbsorbableSutures" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5">
	<div class="container tab-pane active border rounded">
		<br />
		<div class="vl p-2">
			<b>Non - Absorbable Sutures</b>
		</div>

		<div class="col-12 py-3">
			<div class="scrolling-wrapper">
				<div class="col-12" *ngIf="mySubProduct">

					<label class="w-100">{{mySubProduct.name}}</label>

					<label *ngIf="mySubProduct.sub">
						<label class="w-100" *ngFor="let item of mySubProduct.sub">
							<label class="w-100">{{item.name}}</label>
							<label class="w-100">{{item.title}}</label>
							<label class="w-100">{{item.descrip}}</label>
							<label class="w-100">{{item.proDescrip}}</label>
						</label>
					</label>

				</div>

				<div class="row">
					<div class="row">
						<div class="col-3 p-2">
							<div class="card">
								<div class="card-header text-center">
									<label class="m-0 text-white">Trusynth Plus</label>
								</div>
								<div class="card-body">
									<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
										alt="Card image cap" style="height: 8rem;">
									<div class="text-center bg-Color py-1 rounded">
										<label class="m-0 colView">View More</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-3 p-2">
							<div class="card">
								<div class="card-header text-center">
									<label class="m-0 text-white">Trusynth Plus</label>
								</div>
								<div class="card-body">
									<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
										alt="Card image cap" style="height: 8rem;">
									<div class="text-center bg-Color py-1 rounded">
										<label class="m-0 colView">View More</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-3 p-2">
							<div class="card">
								<div class="card-header text-center">
									<label class="m-0 text-white">Trusynth Plus</label>
								</div>
								<div class="card-body">
									<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
										alt="Card image cap" style="height: 8rem;">
									<div class="text-center bg-Color py-1 rounded">
										<label class="m-0 colView">View More</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-3 p-2">
							<div class="card">
								<div class="card-header text-center">
									<label class="m-0 text-white">Trusynth Plus</label>
								</div>
								<div class="card-body">
									<img class="card-img-top" src="../../assets/images/imgTrusynthPlus.png"
										alt="Card image cap" style="height: 8rem;">
									<div class="text-center bg-Color py-1 rounded">
										<label class="m-0 colView">View More</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->