import { Component, ViewEncapsulation, OnInit } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
	selector: 'app-wound-closure',
	templateUrl: './woundClosure.component.html',
	styleUrls: ['./woundClosure.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class WoundClosureComponent implements OnInit {

	// public woundManagement: string;
	// public mySubProduct: any;
	// public subProduct = {
	// 	"KNOT-CRYIL": {
	// 		name: 'KNOT-CRYIL',
	// 		subName: [
	// 			{
	// 				'title': "POLYGLACTIN 910 SUTURES"
	// 			},
	// 			{
	// 				'descrip': "Braided synthetic absorbable Polyglactin 910 violet or undyed sutures with drilled end curved needles"
	// 			},
	// 			{
	// 				'proDescrip': "KNOT -CRYIL , Our Polyglactin 910 suture are known for their high quality and undoubted performance. Made-up of braided and coated 90% Polyglycolic acid and 10% lactic acid, these are superior in absorption with shelf life of 5 years. Available in convenient and safe packaging, these sutures are appreciated by our most customers."
	// 			}
	// 		],
	// 		advantages: [
	// 			{
	// 				'advan': "High tensile strength"
	// 			}
	// 			// {
	// 			// 	"Excellent knotting"
	// 			// }
	// 			// {
	// 			// 	"Smooth passage through tissue"
	// 			// },
	// 			// {
	// 			// 	"Silicon coated atraumatic needle."
	// 			// },
	// 			// {
	// 			// 	"Easy retrieval pack"
	// 			// }
	// 		],
	// 		absProfile: [
	// 			{
	// 				'absPro': "Wound Support: 27-30 Days"
	// 			}
	// 		],
	// 		range: [
	// 			{
	// 				'range': "U.S.P Sizes available: 6-0 to 2"
	// 			}
	// 			// {
	// 			// 	"Suture length available with needle: 45cms to 180cms"
	// 			// },
	// 			// {
	// 			// 	"Needle lengths: 12mm to 50mm across all needle point profiles"
	// 			// }
	// 		],
	// 		image: 'img.src'
	// 	},

	// 	"B": {
	// 		name: 'bb',
	// 		image: 'cc'
	// 	},

	// }

	// constructor(private _activatedRoute: ActivatedRoute) {

	// 	this.getParam();
	// }

	// public ngOnInit() {
	// 	this.mySubProduct = this.subProduct[this.woundManagement]

	// 	console.log("mySubProduct ", this.mySubProduct);
	// 	// console.log("subProduct ", this.subProduct);
	// 	// console.log("woundManagement ", this.woundManagement);
	// }

	// private getParam() {
	// 	this._activatedRoute.params.subscribe(
	// 		(param) => {
	// 			this.woundManagement = param['type']
	// 			console.log('producttype subProduct ', this.subProduct);
	// 		}
	// 	)
	// }

	public ngOnInit() {
		$(document).ready(function () {
			$(this).scrollTop(0);
		});
	}

}
