<div class="container col-12 bg-images-woundClosure">
    <div class="overlay-woundClosure"></div>
</div>

<div class="container col-12 col-md-12 col-lg-10 col-xl-10">
    <div class="row m-2">
        <div class="col-1">
            <div>
                <img class="float-right" src="../assets/images/iconWoundClosure.png" alt="WoundClosure icon"
                    style="height: 2.4rem;" />
            </div>
        </div>
        <div class="col-11 p-0 m-0 fontMontserrat">
            <div class="py-2">
                <b>Wound Management</b>
            </div>
            <div class="mb-5">
                <p class="text-justify">
                    MANSAR SCIENTIFIC brings you the widest fully certified range of wound
                    closure products: Absorbable and non Absorbable sutures, hernia mesh ,
                    Tissue adhesives , Hemostats and skin stapler in multiple
                    specifications to match your needs.
                </p>
            </div>
        </div>
    </div>
</div>

<div id="boxTabs">
    <div class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs fontMontserrat" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#absorbableSutures">Surgical Sutures</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#herniaSolution">Hernia Solution</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#skinStaplers">Skin Staplers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tissueAdhesive">Tissue Adhesive</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#haemostats">Haemostats</a>
            </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content fontMontserrat">
            <div id="absorbableSutures" class="container tab-pane active border rounded">
                <br />
                <div class="vl p-2">
                    <b>Absorbable Sutures</b>
                </div>

                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-CRYIL</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTcRIYIL.png" alt="Card image cap"
                                            style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-CRYIL">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-CRYIL FAST</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTcRYILfAST.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-CRYIL-FAST">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-MONO</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtMONO.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-MONO">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-PDO</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtPDO.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-PDO">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-GUT CHROMIC</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTgUTcHROMIC.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-GUT-CHROMIC">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-GUT PLAIN</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTgUTpLAIN.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-GUT-PLAIN">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <div class="vl p-2">
                    <b>Non - Absorbable Sutures</b>
                </div>

                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-SILK</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtSILK.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-SILK">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-BOND</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOTbOND.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-BOND">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-LON</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtLON.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-LON">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-LENE</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOTlENE.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-LENE">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT-STEEL</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtSTEEL.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/KNOT-STEEL">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div id="herniaSolution" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Hernia Solution</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row fontColor">
                        <!-- <div class="row"> -->
                        <!-- <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT LENE-MESH</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOTLENEmESH.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">KNOT LENE-COMP MESH</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/KNOtSROBmESH.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        <div class="container col-12 col-md-12 col-lg-10 col-xl-10 ViewProductLabel">
                            <div class="row">

                                <div class="col-12">
                                    <div>
                                        <h4>KNOT LENE-MESH</h4>
                                    </div>
                                    <div>
                                        <div>
                                            <h6>POLYPROPYLENE HERNIA MESH</h6>
                                            <strong>Monofilament Polypropylene knitted Micropore and Macropore
                                                Surgical Mesh</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center py-3">
                                    <img src="../../assets/MSImages/woundClosure/KNOTLENEmESH.png" alt="KNOTLENEmESH"
                                        style="height: 15rem;">
                                </div>
                                <div class="col-12">
                                    <p>KNOT-LENE MESH , Our Hernia Mesh are high quality polypropylene meshes, for
                                        repair of hernia and other fascia deficiencies, which require the addition
                                        of a bridging or reinforcing material to obtain the desired surgical result.
                                        Bio-compatible in nature, these are good in strength and offer burst
                                        resistance for permanent support. The mesh offers long stability and
                                        superior performance.</p>
                                </div>

                                <div id="advantageProduct" class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                ADVANTEGES :
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Non absorbable, Monofilament Polypropylene Mesh</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Unique knitting design</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Bio compatible</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Flexible, strong, elastic & Transparent mesh</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Ideal porosity for high visibility & colonization</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>High strength & burst resistance for permanent
                                                        support</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Flexible for any anatomic placement</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>No shrinkage-provides long term material
                                                        stability</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                MESH THICKNESS:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Regular Thick Mesh-0.43mm-0.47mm.</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Regular Thin Lapro Mesh–0.57mm-0.69mm</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Macro-pore Mesh-0.69mm-0.74mm</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                RECOMMENDED USAGE:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Open inguinal hernia</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Laparoscopic inguinal hernia (TAPP & TEP)</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Open incision hernia</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Fascia defect</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Abdominal wall reconstruction</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                RANGE:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Single use medical product, available sizes 6x 11 cms,
                                                        7.6x15 cms , 15x15 cms , 30x30 cms</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="container col-12 col-md-12 col-lg-11 col-xl-11 ViewProductLabel pt-5">
                            <div class="row">

                                <div class="col-12">
                                    <div>
                                        <h4>KNOT LENE-COMP MESH</h4>
                                    </div>
                                    <div>
                                        <div>
                                            <h6>COMPOSITE MESH</h6>
                                            <strong>Polypropylene and Polyglycolic acid knitted Micropore and
                                                Macropore Surgical Mesh</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center py-3">
                                    <img src="../../assets/MSImages/woundClosure/KNOtSROBmESH.png" alt="KNOtSROBmESH"
                                        style="height: 15rem;">
                                </div>
                                <div class="col-12">
                                    <p>KNOTSORB MESH, Our composite mesh is made up of Polypropylene and
                                        Polyglycolic acid threads knitted together. It is a light weight mesh with a
                                        high initial tensile strength as compared to polypropylene mesh.
                                        Polyglycolic acid part of the mesh get absorbed in 3 months of time, hence
                                        only half of the biomass remains inside body. Scar plate formed also is
                                        flexible as compared to regular polypropylene mesh.</p>
                                </div>

                                <div id="advantageProduct" class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                ADVANTEGES :
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Polypropylene-Polyglycolic acid composite mesh</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Pore size 1.2-2.0mm</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Less stiffness, better flexibility</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Less mesh shrinkage</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Flexible scar plate formation</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Less foreign body reaction</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Only 50% of original mesh weight remains after PGA
                                                        absorption</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                                <strong>
                                                    MESH THICKNESS:
                                                </strong>
                                            </div>
                                            <div class="listAbout">
                                                <ul class="p-0">
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Regular Thick Mesh-0.43mm-0.47mm.</label>
                                                    </ol>
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Regular Thin Lapro Mesh–0.57mm-0.69mm</label>
                                                    </ol>
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Macro-pore Mesh-0.69mm-0.74mm</label>
                                                    </ol>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                                <strong>
                                                    RECOMMENDED USAGE:
                                                </strong>
                                            </div>
                                            <div class="listAbout">
                                                <ul class="p-0">
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Open inguinal hernia</label>
                                                    </ol>
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Laparoscopic inguinal hernia (TAPP & TEP)</label>
                                                    </ol>
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Open incision hernia</label>
                                                    </ol>
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Fascia defect</label>
                                                    </ol>
                                                    <ol class="py-2">
                                                        <img class="pr-2" src="../../assets/images/arrowOrange.png"
                                                            alt="" style="height: 1.5rem;" />
                                                        <label>Abdominal wall reconstruction</label>
                                                    </ol>
                                                </ul>
                                            </div>
                                        </div> -->

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                RANGE:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Single use medical product, available sizes 6x 11 cms,
                                                        7.6x15 cms , 15x15 cms ,30x30 cms.</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- </div> -->
                    </div>
                </div>
            </div>

            <div id="skinStaplers" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Skin Staplers</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row fontColor">
                        <!-- <div class="row">
                            <div class="col-8 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">ESSEN-STAPLER</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top"
                                            src="../../assets/MSImages/woundClosure/ESSENsKINsTAPLER.jpg"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->


                        <div class="container col-12 col-md-12 col-lg-11 col-xl-11 ViewProductLabel">
                            <div class="row">
                                <div class="col-12">
                                    <div>
                                        <h4>ESSEN-STAPLER</h4>
                                    </div>
                                    <div>
                                        <div>
                                            <h6>Surgical skin stapler</h6>
                                            <strong>Single use Surgical skin stapler 25, 35 and 55 wide pins with
                                                stapler remover</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center py-3">
                                    <img src="../../assets/MSImages/woundClosure/ESSENsKINsTAPLER.jpg"
                                        alt="ESSENsKINsTAPLER" style="height: 15rem;">
                                </div>
                                <div class="col-12">
                                    <p>ESSEN-STAPLER, Our surgical skin staplers are excellent while stapling the skin
                                        with flexible movements, fully retracting anvil permits staple release while
                                        moving forward or backward unlike competitive models which require you to “back
                                        off” before moving forward, releases the staple when the handle is fully
                                        depressed. Complete staple formation is confirmed by Audible and Tactile
                                        Feedback. It is User friendly excellent visibility, Clear cartridge with
                                        centerline pointer and 90º visual access.<br>
                                        Our skin stapler is along with ESSEN -STAPLER REMOVER simple, quick and easy to
                                        use with an ergonomic design to facilitate maximum control and offers
                                        exceptional performance.</p>
                                </div>

                                <div id="advantageProduct" class="col-12">
                                    <!-- <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                ADVANTEGES :
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2" *ngFor="let item of mySubProduct.advantages">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Non absorbable, Monofilament Polypropylene Mesh</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Unique knitting design</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Bio compatible</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Flexible, strong, elastic & Transparent mesh</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Ideal porosity for high visibility & colonization</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>High strength & burst resistance for permanent
                                                        support</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Flexible for any anatomic placement</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>No shrinkage-provides long term material
                                                        stability</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                MESH THICKNESS:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Regular Thick Mesh-0.43mm-0.47mm.</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Regular Thin Lapro Mesh–0.57mm-0.69mm</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Macro-pore Mesh-0.69mm-0.74mm</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                RECOMMENDED USAGE:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Open inguinal hernia</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Laparoscopic inguinal hernia (TAPP & TEP)</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Open incision hernia</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Fascia defect</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Abdominal wall reconstruction</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div> -->

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                RANGE:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Sterile and single use medical device sizes , has 25,35,55
                                                        pins regular , wide and large.</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tissueAdhesive" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Tissue Adhesive</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row fontColor">
                        <!-- <div class="row">
                            <div class="col-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">SKIN GLUE</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/SKINgLUE.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <label class="m-0 colView">View More</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="container col-12 col-md-12 col-lg-11 col-xl-11 ViewProductLabel">
                            <div class="row">

                                <div class="col-12">
                                    <div>
                                        <h4>SKIN GLUE</h4>
                                    </div>
                                    <div>
                                        <div>
                                            <h6>Topical Tissue adhesive</h6>
                                            <strong>N-butyl 2-Cyanoacrylate topical tissue adhesive</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center py-3">
                                    <img src="../../assets/MSImages/woundClosure/SKINgLUE.png" alt="SKINgLUE"
                                        style="height: 15rem;">
                                </div>
                                <div class="col-12">
                                    <p>SKIN GLUE , Our topical tissue adhesive is very convenient to use for closure of
                                        any topical cuts , wounds and incisions including laparoscopic incisions , it
                                        easily approximates edges with minimal tension without any evidence of infection
                                        , reduces trauma and has an excellent cosmetic outcomes. Closure procedures are
                                        relatively simple, quick and less time consuming.</p>
                                </div>

                                <div id="advantageProduct" class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                APPLICATION :
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>General surgery – appendicitis, laparoscopy, hernia ,
                                                        vascular surgery</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Dental surgery – dental implants, periodontal surgery,
                                                        gingivectomy, extracting sites</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Gynecology – caesarean , tubectomy and hysterectomy</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Orthopedic – bone graft ,tendon repair, , cartilage
                                                        binding</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Surgical oncology – breast and thyroid surgery</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Pediatrics – pediatric lacerations</label>
                                                </ol>
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>Plastic surgery – cleft lip, excision of scars, skin
                                                        graft</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 colaboutPara">
                                            <strong>
                                                RANGE:
                                            </strong>
                                        </div>
                                        <div class="listAbout">
                                            <ul class="p-0">
                                                <ol class="py-2">
                                                    <img class="pr-2" src="../../assets/images/arrowOrange.png" alt=""
                                                        style="height: 1.5rem;" />
                                                    <label>It is a sterile and single use medical device sizes 0.25 ml ,
                                                        0.5 ml and 1 ml.</label>
                                                </ol>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="haemostats" class="container tab-pane border rounded">
                <br />
                <div class="vl p-2">
                    <b>Haemostats</b>
                </div>
                <div class="col-12 py-3">
                    <div class="row">
                        <div class="row">
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">SECU WAX</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/SECUwAX.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/SECU-WAX">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">OXICEL</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/OXICEL.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/OXICEL">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                                <div class="card">
                                    <div class="card-header text-center">
                                        <label class="m-0 text-white">ALPHA HAEMO SPONGE</label>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top" src="../../assets/MSImages/woundClosure/HEAMOsPON.png"
                                            alt="Card image cap" style="height: 8rem;">
                                        <div class="text-center bg-Color py-1 rounded">
                                            <a routerLink="/products/ALPHA-HAEMO-SPONGE">
                                                <label class="m-0 colView">View More</label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- // woundClosure list here // -->
<!-- <div class="col-12">

	<div *ngFor="let item of products">

		<div class="col-12 ">
			<label>{{ item.name}}</label>
		</div>

		<div class="w-100">
			<a class="w-100 " routerLink="/products/{{item.name}}">{{item.image}}</a>
		</div>

	</div>

</div> -->

<!-- <div id="nonAbsorbableSutures" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5">
    <div class="container tab-pane active border rounded">
        <br />
        <div class="vl p-2">
            <b>Non - Absorbable Sutures</b>
        </div>

        <div class="col-12 py-3">
            <div class="row">
                <div class="row">
                    <div class="col-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">KNOT-SILK</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtSILK.png"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <label class="m-0 colView">View More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">KNOT-BOND</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOTbOND.png"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <label class="m-0 colView">View More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">KNOT-LON</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtLON.png"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <label class="m-0 colView">View More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">KNOT-LENE </label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOTlENE.png"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <label class="m-0 colView">View More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-2">
                        <div class="card">
                            <div class="card-header text-center">
                                <label class="m-0 text-white">KNOT-STEEL</label>
                            </div>
                            <div class="card-body">
                                <img class="card-img-top" src="../../assets/MSImages/woundClosure/KNOtSTEEL.png"
                                    alt="Card image cap" style="height: 8rem;">
                                <div class="text-center bg-Color py-1 rounded">
                                    <label class="m-0 colView">View More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->