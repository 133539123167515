<!-- <app-home></app-home>
<app-about></app-about> -->
<!-- <div id="viewNavBar" class="container col-12 col-md-12 col-lg-10 col-xl-10 pt-4 px-0 ">
  <nav class="navbar navbar-expand-lg navbar-light p-0">
    <div class="col-1 col-md-1 col-lg-2 col-xl-2">
      <a class="navbar-brand" href="#">
        <img
          src="../assets/images/nav-logo.png"
          alt="Mn-logo"
          style="width: 15rem;"
        />
      </a>
    </div>

    <div class="col-3 col-md-2 col-lg-2 col-xl-2">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div
      class="col-12 col-md-12 col-lg-8 col-xl-8 collapse navbar-collapse"
      id="navbarTogglerDemo02"
    >
      <div
        class="container col-12 d-flex justify-content-center align-items-center"
      >
        <ul class="col-12 navbar-nav mr-auto mt-2 mt-lg-0">
          <div class="col-2"></div>
          <div class="col-2 col-xl-2">
            <li class="nav-item active">
              <a class="nav-link text-white" href="#"
                >About&nbsp;Us<span class="sr-only">(current)</span></a
              >
            </li>
          </div>
          <div class="col-2 col-xl-2">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle text-white"
                href="#"
                data-toggle="dropdown"
              >
                Produtcs
              </a>
              <ul class="dropdown-menu fade-up">
                <li>
                  <a class="dropdown-item" href="#">Wound&nbsp;Closure</a>
                  <div class="dropdown-divider mx-2 my-0"></div>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Cardiology</a>
                  <div class="dropdown-divider mx-2 my-0"></div>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Haemodialysis</a>
                  <div class="dropdown-divider mx-2 my-0"></div>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Urology</a>
                  <div class="dropdown-divider mx-2 my-0"></div>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Infusion</a>
                  <div class="dropdown-divider mx-2 my-0"></div>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Consumables</a>
                </li>
              </ul>
            </li>
          </div>
          <div class="col-2 col-xl-2">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Our&nbsp;Market</a>
            </li>
          </div>
          <div class="col-2 col-xl-2">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Quality</a>
            </li>
          </div>
          <div class="col-2 col-xl-2">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Contact&nbsp;Us</a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </nav>
  <hr class="m-1 bg-white my-2" />
</div> -->

<!-- <div id=""> -->
<div id="viewContent" class="container col-12 col-md-12 col-lg-10 col-xl-10 px-0">
	<!-- <nav class="navbar navbar-expand-lg fixed-top p-0">
			<div class="col-1 col-md-1 col-lg-2 col-xl-2">
				<a class="navbar-brand" href="#">
					<img src="../assets/images/nav-logo.png" alt="Mn-logo" class="shadowfilter" style="width: 15rem;" />
				</a>
			</div>

			<div class="col-3 col-md-2 col-lg-2 col-xl-2">
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
					aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
			</div>

			<div class="col-12 col-md-12 col-lg-8 col-xl-8 collapse navbar-collapse" id="navbarTogglerDemo02">
				<div class="container col-12 d-flex justify-content-center align-items-center">
					<ul class="col-12 navbar-nav mr-auto mt-2 mt-lg-0">
						<div class="col-2"></div>
						<div class="col-2 col-xl-2">
							<li class="nav-item active">
								<a class="nav-link text-white" routerLink="/aboutus">About&nbsp;Us<span
										class="sr-only">(current)</span></a>
							</li>
						</div>
						<div class="col-2 col-xl-2">
							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle text-white" href="#productOur" data-toggle="dropdown"
									pageScroll [pageScrollDuration]="2500">
									Products
								</a>
								<ul class="dropdown-menu fade-up">
									<li>
										<a class="dropdown-item" routerLink="/woundManagement">Wound&nbsp;Closure</a>
										<div class="dropdown-divider mx-2 my-0"></div>
									</li>
									<li>
										<a class="dropdown-item" href="#">Cardiology</a>
										<div class="dropdown-divider mx-2 my-0"></div>
									</li>
									<li>
										<a class="dropdown-item" routerLink="/haemodialysis">Haemodialysis</a>
										<div class="dropdown-divider mx-2 my-0"></div>
									</li>
									<li>
										<a class="dropdown-item" routerLink="/urology">Urology</a>
										<div class="dropdown-divider mx-2 my-0"></div>
									</li>
									<li>
										<a class="dropdown-item" routerLink="/infusion">Infusion</a>
										<div class="dropdown-divider mx-2 my-0"></div>
									</li>
									<li>
										<a class="dropdown-item" routerLink="/consumables">Consumables</a>
									</li>
								</ul>
							</li>
						</div>
						<div class="col-2 col-xl-2">
							<li class="nav-item">
								<a class="nav-link text-white" routerLink="/ourmarket">Our&nbsp;Market</a>
							</li>
						</div>
						<div class="col-2 col-xl-2">
							<li class="nav-item">
								<a class="nav-link text-white" routerLink="/qualityManagement">Quality</a>
							</li>
						</div>
						<div class="col-2 col-xl-2">
							<li class="nav-item">
								<a class="nav-link text-white" routerLink="/contactUs">Contact&nbsp;Us</a>
							</li>
						</div>
					</ul>
				</div>
			</div>
		</nav> -->
	<!-- <nav class="navbar navbar-expand-lg fixed-top w-100 col-12 col-md-12 col-lg-10 col-xl-10">
			<div class="container">
				<a href="#" class="navbar-brand text-uppercase font-weight-bold">
					<img src="../../assets/images/nav-logo.png" alt="logo" class="header-logo py-1 shadowfilter"
						style="width: 15rem;">
				</a>
				<button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
					class="navbar-toggler navbar-toggler-right">
					<i class="fa fa-bars"></i>
				</button>

				<div id="navbarSupportedContent" class="collapse navbar-collapse">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item active">
							<a routerLink="/aboutus" data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold">About&nbsp;Us
								<span class="sr-only">(current)</span>
							</a></li>
						<li class="nav-item">
							<a href="#productOur" data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold" pageScroll
								[pageScrollDuration]="2500">Products
							</a>
							<ul class="dropdown-menu fade-up">
								<li>
									<a class="dropdown-item" routerLink="/woundManagement">Wound&nbsp;Closure</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" href="#">Cardiology</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/haemodialysis">Haemodialysis</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/urology">Urology</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/infusion">Infusion</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/consumables">Consumables</a>
								</li>
							</ul>
						</li>
						<li class="nav-item">
							<a routerLink="/ourmarket" data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold">Our&nbsp;Market
							</a>
						</li>
						<li class="nav-item">
							<a routerLink="/qualityManagement" data-toggle="collapse"
								data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold">Quality
							</a>
						</li>
						<li class="nav-item">
							<a routerLink="/contactUs" data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold">Contact&nbsp;Us
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav> -->

	<header class="header">
		<nav class="navbar navbar-expand-lg">
			<div class="container">
				<a href="#" class="navbar-brand text-uppercase font-weight-bold">
					<img src="../assets/images/nav-logo.png" alt="Mn-logo" class="shadowfilter" style="width: 14rem;" />
				</a>
				<button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
					class="navbar-toggler navbar-toggler-right">
					<i class="fa fa-bars"></i>
				</button>

				<div id="navbarSupportedContent" class="collapse navbar-collapse">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item active">
							<a data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold" routerLink="/aboutus">About&nbsp;Us
								<span class="sr-only">(current)</span>
							</a>
						</li>
						<li class="nav-item dropdown">
							<a data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link dropdown-toggle text-uppercase font-weight-bold" data-toggle="dropdown"
								href="#productOur" pageScroll [pageScrollDuration]="2500">Products
							</a>
							<ul class="dropdown-menu fade-up mt-3">
								<li>
									<a class="dropdown-item" routerLink="/surgicalConsumables">Surgical Consumables</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/infusion">Infusion</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/urology">Urology</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/haemodialysis">Haemodialysis</a>
									<div class="dropdown-divider mx-2 my-0"></div>
								</li>
								<li>
									<a class="dropdown-item" routerLink="/woundManagement">Wound Management</a>									
								</li>
							</ul>
						</li>
						<li class="nav-item">
							<a data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold" routerLink="/ourmarket">Our&nbsp;Market
							</a>
						</li>
						<li class="nav-item">
							<a data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold" routerLink="/qualityManagement">Quality
							</a>
						</li>
						<li class="nav-item">
							<a data-toggle="collapse" data-target=".navbar-collapse.show"
								class="nav-link text-uppercase font-weight-bold" routerLink="/contactUs">Contact&nbsp;Us
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
	<hr class="m-1 bg-white my-2" />
</div>

<!-- </div> -->

<router-outlet></router-outlet>

<footer>
	<hr class="bottomLine my-0" />

	<div id="bgColorBottom">
		<div class="container col-12 col-md-12 col-lg-10 col-xl-10">
			<div class="row align-items-center p-3">
				<div class="col-12 col-md-10 col-lg-10 col-xl-10 py-2">
					<img src="../assets/images/msBottom.png" alt="" style="height: 2rem;" />
				</div>
				<div class="col-12 col-md-2 col-lg-2 col-xl-2 py-2 text-center">
					<div class="container">
						<div class="row justify-content-end">
							<div class="col-4">
								<img src="../assets/images/iconlinkedin.png" alt="linkedin icon"
									style="height: 2rem;" />
							</div>
							<div class="col-4">
								<img src="../assets/images/iconFb.png" alt="Fb icon" style="height: 2rem;" />
							</div>
							<div class="col-4">
								<img src="../assets/images/iconYouTube.png" alt="YouTube icon" style="height: 2rem;" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="bgFooterColor">
		<div class="container col-12 col-md-12 col-lg-10 col-xl-10 py-4">
			<div class="row col-12">
				<div class="col-12 col-md-4 col-lg-4 col-xl-4 py-1 px-0">
					<div class="col-12">
						<h5>Head&nbsp;Office</h5>
					</div>

					<div class="row col-12">
						<div class="col-2 px-0 text-center">
							<img src="../assets/images/iconLocation.png" alt="" style="height: 1rem;" />
						</div>
						<div class="col-10 px-0">
							<address class="m-0">
								<span>
									Mansar&nbsp;Scientific&nbsp;LLP<br />Bangalore&nbsp;-&nbsp;560086,&nbsp;India
								</span>
							</address>
						</div>
					</div>

					<div class="row col-12">
						<div class="col-2 px-0 text-center">
							<img src="../assets/images/iconEmail.png" alt="" style="height: 1rem;" />
						</div>
						<div class="col-10 px-0">
							<address class="m-0">
								<span>
									<a class="text-white" href="mailto:sales@mnssci.com">sales@mnssci.com</a>
								</span>
							</address>
						</div>
					</div>

					<div class="row col-12">
						<div class="col-2 px-0 text-center">
							<img src="../assets/images/iconRing.png" alt="" style="height: 1rem;" />
						</div>
						<div class="col-10 px-0">
							<address class="m-0">
								<span>
									+91-9900747949
								</span>
							</address>
						</div>
					</div>

					<div class="row col-12">
						<div class="col-2 px-0 text-center">
							<img src="../assets/images/iconGlobe.png" alt="" style="height: 1rem;" />
						</div>
						<div class="col-10 px-0">
							<address class="m-0">
								<span>
									<a class="text-white" href="https://www.mnssci.com">www.mnssci.com</a>
								</span>
							</address>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 col-lg-4 col-xl-4 py-1">
					<div class="col-12 px-0">
						<h5>Quick&nbsp;Links</h5>
					</div>

					<div><a class="text-white" routerLink="/home">Home</a></div>
					<div><a class="text-white" routerLink="/aboutus">About Us</a></div>
					<div><a class="text-white" routerLink="/ourmarket">Our Market</a></div>
					<div><a class="text-white" routerLink="/qualityManagement">Quality</a></div>
					<div><a class="text-white" routerLink="/contactUs">Contact Us</a></div>
				</div>
				<div class="col-12 col-md-4 col-lg-4 col-xl-4 py-1">
					<div class="col-12 px-0">
						<h5>Products</h5>
					</div>

					<div class="row">
						<div class="col-12 col-md-12 col-lg-6 col-xl-6">
							<div><a class="text-white" routerLink="/surgicalConsumables">Surgical Consumables</a></div>
							<div><a class="text-white" routerLink="/infusion">Infusion</a></div>
							<div><a class="text-white" routerLink="/urology">Urology</a></div>
						</div>
						<div class="col-12 col-md-12 col-lg-6 col-xl-6">
							<div><a class="text-white" routerLink="/haemodialysis">Haemodialysis</a></div>
							<div><a class="text-white" routerLink="/woundManagement">Wound Management</a></div>

						</div>
					</div>
				</div>
			</div>
			<hr class="bg-white m-1 my-2" />

			<div class="col-12 text-center text-white">
				<h6>
					&copy; Mansar Scientific Limited. All Rights Reserved.
				</h6>
			</div>
		</div>
	</div>
</footer>