<div id="consumablesProducts" class="p-0 m-0">
    <div class="container col-12 bg-images-consumables">
        <div class="overlay-consumables"></div>
    </div>

    <div class="container col-12 col-md-12 col-lg-10 col-xl-10">
        <div class="row m-2">
            <div class="col-1">
                <div>
                    <img class="float-right" src="../assets/images/iconConsumables.png" alt="consumables icon"
                        style="height: 2.4rem;" />
                </div>
            </div>
            <div class="col-11 p-0 m-0 fontMontserrat">
                <div class="py-2">
                    <b>Consumables</b>
                </div>
                <div class="mb-5">
                    <p class="text-justify">
                        MANSAR SCIENTIFC bring you the widest, fully certified range of Surgical consumables like Micro
                        porous tapes , transpore tapes , Gloves , Gowns , Drapes , IV fixation dressing , Bandages ,
                        Gauze ,
                        swabs ,Alcohol wipes,Santizer, face mask in multiple specifications to match your needs
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div id="consumables-Product" class="container col-12 col-md-12 col-lg-10 col-xl-10 py-5">
        <div class="container tab-pane active border rounded fontMontserrat">
            <br />
            <div class="vl p-2">
                <b>Consumables Products</b>
            </div>

            <div class="col-12 py-3">
                <div class="row">
                    <div class="row">
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">MICROS</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/MICROS.jpeg"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/MICROS">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">SAFEHANS SURGICAL</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top"
                                        src="../../assets/MSImages/Consumable/SAFEHANSSURGICAL.jpeg"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/SAFEHANS-SURGICAL">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">SAFEHANS EXAM</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/SAFEHANSeXAM.png"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/SAFEHANS-EXAM">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">ENWRAP DRAPE</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/ENWRAPDRAPES.png"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/ENWRAP-DRAPE">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">ENWRAP GOWN</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/ENWRAPGOWN.jpg"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/ENWRAP-GOWN">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">IV FIX</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/IVFIX.jpg"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/IV-FIX">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">ESCU BANDAGE</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/ESCUBAND.png"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/ESCU-BANDAGE">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">ESCU GAUZE</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/ESCUGUAZE.png"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/ESCU-GAUZE">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">CONCEAL MASK</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/CONCEALMASK.png"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/CONCEAL-MASK">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">CONSHIELD</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/CONSHIELD.jpeg"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/CONSHIELD">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">CLENSOR WIPES</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top" src="../../assets/MSImages/Consumable/CLENSORWIPES.png"
                                        alt="Card image cap" style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/CLENSOR-WIPES">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3 col-xl-3 p-2">
                            <div class="card">
                                <div class="card-header text-center">
                                    <label class="m-0 text-white">CLENSOR SANITIZER</label>
                                </div>
                                <div class="card-body">
                                    <img class="card-img-top"
                                        src="../../assets/MSImages/Consumable/CLENSORSANITIZER.png" alt="Card image cap"
                                        style="height: 8rem;">
                                    <div class="text-center bg-Color py-1 rounded">
                                        <a routerLink="/products/CLENSOR-SANITIZER">
                                            <label class="m-0 colView">View More</label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>