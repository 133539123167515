<div class="container col-12 bg-images-contactus">
    <div class="overlay-contactus d-flex align-items-center">
        <div class="container col-10">
            <div class="row">
                <div class="col-12">
                    <h1 class="contactusFont">Contact Us</h1>
                </div>
                <!-- <div class="col-12">
					<label class="labelqualityFont">
						Thrive for better quality of life for a healthier future
					</label>
				</div> -->
            </div>
        </div>
    </div>
</div>

<div id="contactsUs" class="container col-10 col-md-10 col-lg-8 col-xl-8 pb-5">
    <div class="row">
        <div class="col-12 getInTouch p-5">
            <div class="col-12">
                <h3>Get in Touch</h3>
            </div>
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6 py-2">
                        <input type="text" class="form-control" id="exampleInputText" aria-describedby="emailHelp"
                            placeholder="Your Name">
                        <hr class="bottomLine my-0 mx-2" />
                        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                            else.</small> -->
                    </div>
                    <div class="form-group col-md-6 py-2">
                        <input type="text" class="form-control" id="exampleInputText" placeholder="Company">
                        <hr class="bottomLine my-0 mx-2" />
                        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                            else.</small> -->
                    </div>
                    <div class="form-group col-md-6 py-2">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Email">
                        <hr class="bottomLine my-0 mx-2" />
                        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                            else.</small> -->
                    </div>
                    <div class="form-group col-md-6 py-2">
                        <input type="text" class="form-control" id="exampleInputPassword1"
                            placeholder="Phone Number">
                        <hr class="bottomLine my-0 mx-2" />
                        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                            else.</small> -->
                    </div>
                </div>
                <div class="form-group py-2">
                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Message"
                        rows="5"></textarea>
                    <hr class="bottomLine my-0 mx-2" />
                </div>
                <button type="submit" class="btn btn-warning text-white submitBnt">Submit
                    <span>
                        <img src="../../assets/images/arrowSubmit.png" alt="">
                    </span>
                </button>
            </form>
        </div>
    </div>

</div>


<!-- <div class="container col-12 col-md-12 col-lg-8 col-xl-8 pb-5"
    style="height: 400px; width:1000px; background-color: #2F686D; margin-bottom: 100px; border-radius: 25px; ">

    <h3 style="padding-top: 30px; padding-left: 20px; padding-bottom: 30px; color: #FFFFFF">
        Get in Touch
    </h3>

    <form action="" method="POST">
        <input name="" type="text" placeholder="Your Name" required=""
            style="float: left; width: 400px; background-color: #2F686D;  border: none; border-bottom: 2px solid; padding-left: 20px;  border-bottom-color:  #47B4AC;   ">

        <input name="" type="text" placeholder="Company" required=""
            style="float: right; width: 400px; background-color: #2F686D;  border: none; border-bottom: 2px solid;  padding-left: 20px;  border-bottom-color:  #47B4AC;   ">
        <br>
        <br>
        <br>
        <input name="" type="text" placeholder="E - Mail" required=""
            style="float: left; width: 400px; background-color: #2F686D;  border: none; border-bottom: 2px solid;  padding-left: 20px;  border-bottom-color:  #47B4AC;   ">
        <input name="" type="text" placeholder="Phone Number" required=""
            style="float: right; width: 400px; background-color: #2F686D;  border: none; border-bottom: 2px solid;  padding-left: 20px;  border-bottom-color:  #47B4AC;   ">
        <br>
        <br>
        <br>
        <textarea name="" id="" rows="2" placeholder="Message"
            style=" width: 100%; background-color: #2F686D;  border: none; border-bottom: 2px solid;  padding-left: 20px;  border-bottom-color:  #47B4AC;   "></textarea>
        <br>
        <br>

        <div>
            <button name="submit-form" type="submit" class="button small"
                style="background-color: #FE721C; color: #FFFFFF; font-weight: bold; float: left; padding: 10px; font-size: 15px; margin-left: 30px; margin-bottom: 30px;">
                Submit&nbsp;
                <span style="margin-top: -2px;">
                    <img class="" src="../../assets/images/arrowSubmit.png">
                </span>

            </button>
        </div>
    </form>

</div> -->