<div class="container col-12 bg-images-quality">
    <div class="overlay-quality d-flex align-items-center">
        <div class="container col-10">
            <div class="row">
                <div class="col-12">
                    <h1 class="qualityFont">Quality Management System</h1>
                </div>
                <!-- <div class="col-12">
					<label class="labelqualityFont">
						Thrive for better quality of life for a healthier future
					</label>
				</div> -->
            </div>
        </div>
    </div>
</div>

<div id="qmsys" class="container col-12 col-md-12 col-lg-10 col-xl-10 pb-5 colView">
    <div class="row">
        <div class="col p-0">
            <div class="text-center">
                <img src="../../assets/images/iconDoubleCheck.png" alt="DoubleCheck" style="height: 2rem;">
            </div>
        </div>
        <div class="col-11 col-md-11 col-lg-11 col-xl-11 p-0 colView">
            <div class="col-12">
                <h4>We double check so you don't have to:</h4>
            </div>
            <div class="text-justify col-12">
                <p>MANSAR SCIENTIFIC follows a superior process of quality checks in the laboratory and documentation
                    practices to ensure result that are high are highly accurate and consistent. Each of our products
                    runs through rigorous quality checks to make sure that the surgeons and physicians can provide
                    better patient treatment and care.</p>
                <p>At our analytical facilities, we maintain and monitor a firm quality of our products, processes and
                    services that consistently meeting BP , Ph.Eur , USP , and other national and international quality
                    standards. We periodically review our quality management systems to keep it updated with any changes
                    in the industry.</p>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col p-0">
            <div class="text-center">
                <img src="../../assets/images/iconKeyFun.png" alt="Key Fun" style="height: 2rem;">
            </div>
        </div>
        <div class="col-11 p-0 colView">
            <div class="col-12">
                <h4>Key functional areas:</h4>
            </div>
            <div class="row col-12 p-0 m-0 py-3">
                <div class="col p-0">
                    <div class="text-center">
                        <img src="../../assets/images/arrowOrange.png" alt="arrowOrange" style="height: 2rem;">
                    </div>
                </div>
                <div class="col-11 col-md-11 col-lg-11 col-xl-11 p-0 text-justify">
                    <b>Quality Control (QC):</b> Responsible for all analysis undertaken at Mansar Scientific, from
                    support during product development and validations to training our customers, The Quality
                    control team is supported by a team of highly qualified professionals.
                </div>
            </div>

            <div class="row p-0 m-0">
                <div class="col p-0">
                    <div class="text-center">
                        <img src="../../assets/images/arrowOrange.png" alt="arrowOrange" style="height: 2rem;">
                    </div>
                </div>
                <div class="col-11 col-md-11 col-lg-11 col-xl-11 p-0 text-justify">
                    <b>Quality Assurance (QA):</b> Responsible for implementing quality systems and releasing all
                    manufactured products. They also ensure synchronization of all systems throughout the company.
                    Following the Guideline defined IN FDA , Auditing responsibility at national and international
                    level, customers, GMP is also under its purview. Further , Our <b>Regulatory Affairs</b> teams
                    ensures compliances with national and international regulatory requirements.
                </div>
            </div>
        </div>
    </div>
</div>