import { Component, ViewEncapsulation, OnInit } from '@angular/core';

declare var $: any;

@Component({
	selector: 'app-urology',
	templateUrl: './urology.component.html',
	styleUrls: ['./urology.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UrologyComponent implements OnInit {

	constructor() { }

	public ngOnInit() {
		$(document).ready(function () {
			$(this).scrollTop(0);
		});
	}

}
